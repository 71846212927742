import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import './styles.scss';

interface ILoaderProps {
  className?: string
  nested?: boolean
}

export const Loader: React.FunctionComponent<ILoaderProps> = ({
  className,
  nested = false
}: ILoaderProps) => {
  const [calcStyles, setCalcStyles] = useState({});
  const loaderRef = useRef(null);

  useEffect(() => {
    if (loaderRef.current) {
      const loader = loaderRef.current as unknown as HTMLDivElement;

      setCalcStyles({
        width: Math.min(loader.offsetHeight, loader.offsetWidth)
      });
    }
  }, []);

  return <div
    className={cn('loader', className, {
      'loader--nested': nested
    })}
  >
    <div
      ref={loaderRef}
      style={calcStyles}
      className="loader__wrapper"
    >
      <div className="box">
        <div className="cat"/>
      </div>
    </div>
  </div>;
};
