import './styles.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useGetTranslations} from '../../utils/hooks/useGetTranslations';
import {TranslationKeys} from '../../utils/constants/routeConstants';
import {
  articleAsyncActions, getTeiArticle, getTeiArticleReferences
} from '../../redux/pageAsyncActions';
import './styles.scss';
import Loader from '../../components/loader';

export const ArticleReferences: React.FunctionComponent = () => {
  const {
    article: {
      article,
      references
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {articleId} = useParams();

  useGetTranslations({
    translationKey: TranslationKeys.Article
  });

  useEffect(() => {
    if (articleId) {
      if (!article || article.id.toString() !== articleId) {
        dispatch(articleAsyncActions[getTeiArticle](articleId));
      }
      dispatch(articleAsyncActions[getTeiArticleReferences](articleId));
    }
  }, [articleId]);

  return article && <div className="references-page">
    <div className="references-page-breadcrumbs">
      {article && <div><Link to={`/article/${article.id}`}>
        <FormattedMessage
          id={'gritx.article.article'}
          defaultMessage={'Article'}
        />
      </Link></div>}
    </div>
    <div className="references-page-header">
      <div className="references-page-header__title">
        <FormattedMessage
          id={'gritx.article.references'}
          defaultMessage={'References'}
        /></div>
    </div>
    <div>
      {!references.length && loading.includes(getTeiArticleReferences) && <Loader className="references-page-loader"/>}
      <div className="references-page-body">
        {references.map((r, i) => {
          return <div className="references-page-body-reference" key={i}>
            <div className="references-page-body-reference-link">
              <Link to={`/article/reference/${article.id}/${r.id}`}>{i + 1}.</Link>
            </div>
            <p>{r.rawReference}</p></div>;
        })}
      </div>
    </div>
  </div>;
};
