import React, {ChangeEvent, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import './styles.scss';
import {addUsersToDialogue, dialogueAsyncActions} from '../../redux/pageAsyncActions';
import Input from '../input';

interface IUsersAddInputProps {
  dialogueId: number
  onClose: () => void;
}

export const UsersAddInput = ({
  dialogueId,
  onClose
}: IUsersAddInputProps): JSX.Element => {
  const dispatch = useDispatch();

  const [emails, setEmails] = useState<string>();

  function handleAddUsers() {
    if (!emails) {
      return;
    }
    dispatch(dialogueAsyncActions[addUsersToDialogue](emails, dialogueId));
    setEmails('');
    onClose();
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setEmails(e.target.value);
  }

  function handleKeyUp(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      handleAddUsers();
    }
  }

  return (
    <div className="users__content">
      <input
        type="text"
        placeholder="Add emails separated by , ; or space"
        value={emails}
        className="users__input"
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      <button className={'users__add-btn'} onClick={handleAddUsers}>+</button>
    </div>
  );
};
