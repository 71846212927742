import React, {ReactElement, useCallback, useState} from 'react';
import {Collapse} from 'react-collapse';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as IconDown} from 'assets/image/library/chevron-down.svg';
import {ReactComponent as IconUp} from 'assets/image/library/chevron-up.svg';

import './styles.scss';

type SituationsProps = {
  children?: ReactElement | null;
};

export const Situations = ({
  children
}: SituationsProps): JSX.Element => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  const onClick = useCallback(
    () => setIsCollapseOpen(!isCollapseOpen),
    [isCollapseOpen]
  );

  return <div className="situations">
    <button className="situations__header" aria-expanded={isCollapseOpen} onClick={onClick}>
      <div className="situations__title">
        <FormattedMessage
          id={'gritx.library.situations.header'}
          defaultMessage={'Which life situations can we help with?'}
        />
      </div>
      {isCollapseOpen ? <IconUp className="situations__arrow" /> : <IconDown className="situations__arrow" />}
    </button>
    <Collapse
      isOpened={isCollapseOpen}>
      <div className="situations__content">{children}</div>
    </Collapse>
  </div>;
};
