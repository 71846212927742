import {useRef, useState} from 'react';
import axios from 'axios';
import {messageCallbackType} from '@stomp/stompjs/src/types';
import {Client, IFrame} from '@stomp/stompjs';
import {StompHeaders} from '@stomp/stompjs/esm6/stomp-headers';
import SockJS from 'sockjs-client';

interface IUseWebsocket {
  isConnected: boolean
  isConnecting: boolean
  sendMessage: (msg: string) => void
  connect: (dialogueId: number, callback: messageCallbackType) => void
  disconnect: () => void
}

export const useWebsocket = (): IUseWebsocket => {
  const ref = useRef(false);
  const [client, setClient] = useState<Client>();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const connect = (dialogueId: number, callback: messageCallbackType) => {
    if (ref.current) {
      return;
    }
    ref.current = true;
    setIsConnecting(true);

    const stompClient = new Client({
      webSocketFactory: () => {
        return new SockJS(process.env.REACT_APP_WEBSOCKET_URL || '');
      },
      connectHeaders: {...axios.defaults.headers.common as StompHeaders},
      debug: (str: string) => {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    });

    stompClient.onConnect = (frame: IFrame) => {
      stompClient.subscribe(`/topic/dialogue/${dialogueId}/reply`, callback, {...axios.defaults.headers.common as StompHeaders});
      setIsConnecting(false);
      setIsConnected(true);
    };

    stompClient.onWebSocketClose = (frame: IFrame) => {
      setIsConnected(false);
    };

    stompClient.onStompError = (frame: IFrame) => {
      console.log('onStompError: ', frame);
    };

    stompClient.onWebSocketError = (frame: IFrame) => {
      console.log('onWebSocketError: ', frame);
      setIsConnected(false);
    };

    stompClient.activate();
    setClient(stompClient);
  };

  function disconnect() {
    if (client) {
      client.deactivate();
    }
    setIsConnecting(false);
    setIsConnected(false);
  }

  function sendMessage(msg: string) {
    if (client && client.connected) {
      client.publish({
        destination: '/app/message',
        body: msg,
        skipContentLengthHeader: true,
        headers: {...axios.defaults.headers.common as StompHeaders}
      });
    } else {
      setIsConnected(false);
      console.log('Send error: Client is disconnected');
    }
  }

  return {
    isConnected,
    isConnecting,
    sendMessage,
    connect,
    disconnect
  };
};
