import './styles.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useGetTranslations} from '../../utils/hooks/useGetTranslations';
import {TranslationKeys} from '../../utils/constants/routeConstants';
import {
  articleAsyncActions, getTeiArticle, getTeiArticleSection
} from '../../redux/pageAsyncActions';
import './styles.scss';
import {closeArticleSection} from '../../redux/article/actions';
import Loader from '../../components/loader';
import Section from '../../components/section';

export const ArticleSection: React.FunctionComponent = () => {
  const {
    article: {
      article,
      section
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {articleId, sectionIndex} = useParams();

  useGetTranslations({
    translationKey: TranslationKeys.Article
  });

  useEffect(() => {
    dispatch(closeArticleSection());
    if (articleId && sectionIndex) {
      if (!article || article.id.toString() !== articleId) {
        dispatch(articleAsyncActions[getTeiArticle](articleId));
      }
      dispatch(articleAsyncActions[getTeiArticleSection](articleId, sectionIndex));
    }
  }, [articleId]);

  return <div>
    {!section && loading.includes(getTeiArticleSection) && <Loader className="section-page-loader"/>}
    {article && section && <div className="section-page">
      <div className="section-page-breadcrumbs">
        {<div className="section-page-breadcrumbs-item"><Link to={`/article/${article.id}`}>
          <FormattedMessage
            id={'gritx.article.article'}
            defaultMessage={'Article'}
          /></Link></div>}
      </div>
      <Section section={section}/>
    </div>}
  </div>;
};
