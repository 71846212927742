import React from 'react';

import {ReactComponent as EmptyHeart} from 'assets/image/library/empty-heart.svg';
import {ReactComponent as FullHeart} from 'assets/image/library/full-heart.svg';

import './styles.scss';

type Props = {
  favorite: boolean;
  activeCard?: boolean;
  disabled?: boolean;
  changeFavorite: () => void;
}

export const FavoriteButton = ({
  favorite,
  activeCard = false,
  disabled = false,
  changeFavorite
}: Props): JSX.Element => {
  return <button className="favorite" disabled={disabled} onClick={changeFavorite}>
    <div className={`${activeCard ? 'favorite__big-icon' : 'favorite__icon'}`}>
      {favorite ? <FullHeart className="favorite__image"/>
        : <EmptyHeart className="favorite__image"/>}
    </div>
  </button>;
};
