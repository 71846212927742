import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';

import Loader from 'components/loader';

import './styles.scss';
import {addBotToDialogue, dialogueAsyncActions, getBots} from '../../redux/pageAsyncActions';
import {BotItem} from './BotItem';

interface IBotListProps {
  dialogueId: number
  closeModal: () => void;
}

export const BotList = ({
  dialogueId,
  closeModal
}: IBotListProps): JSX.Element => {
  const {
    dialogue: {bots}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dialogueAsyncActions[getBots]());
  }, []);

  function handleAddBot(botId: number) {
    dispatch(dialogueAsyncActions[addBotToDialogue](botId, dialogueId));
    closeModal();
  }

  return (
    <>
      {bots.length
        ? <div className="bots__content">
          {bots.map((item, index) => {
            return <BotItem key={index} item={item} addBot={handleAddBot} />;
          })}
        </div>
        : <Loader nested/>
      }
    </>
  );
};
