import {IErrorActionPayload} from 'redux/interface';
import {ERROR_LOGIN_CLEAR, ERROR_LOGIN_SET} from 'redux/auth/actions';
import {IStatusActionHandler, IStatusInitialState} from './interfaces';
import {CLEAR_ERROR, SET_ERROR, START_LOADING, StatusActionsType, STOP_LOADING} from './actions';
import _set from 'lodash/set';
import _unset from 'lodash/unset';

const statusInitialState: IStatusInitialState = {
  loading: [],
  errors: {},
  errorLogin: false
};
const setErrorLogin = ({
  state
}: IStatusActionHandler): IStatusInitialState => {
  return {
    ...state,
    errorLogin: true
  };
};
const clearErrorLogin = ({
  state
}: IStatusActionHandler): IStatusInitialState => {
  return {
    ...state,
    errorLogin: false
  };
};
const startLoading = ({
  state,
  payload
}: IStatusActionHandler): IStatusInitialState => {
  return {
    ...state,
    loading: [...state.loading, payload as string]
  };
};
const stopLoading = ({
  state,
  payload
}: IStatusActionHandler): IStatusInitialState => {
  return {
    ...state,
    loading: [...state.loading.filter(item => item !== payload)]
  };
};
const setError = ({
  state,
  payload
}: IStatusActionHandler): IStatusInitialState => {
  const payloadData = payload as IErrorActionPayload;

  _set(state, `errors.${payloadData.key}`, {
    error: payloadData.value,
    count: 1 + state.errors[payloadData.key]?.count || 0
  });

  return {
    ...state
  };
};
const clearError = ({
  state,
  payload
}: IStatusActionHandler): IStatusInitialState => {
  _unset(state, `errors.${payload}`);

  return {
    ...state
  };
};

const statusReducerHandlers = new Map([
  [ERROR_LOGIN_SET, setErrorLogin],
  [ERROR_LOGIN_CLEAR, clearErrorLogin],
  [START_LOADING, startLoading],
  [STOP_LOADING, stopLoading],
  [CLEAR_ERROR, clearError],
  [SET_ERROR, setError]
]);

const statusReducer = (state = statusInitialState, action: StatusActionsType): IStatusInitialState => {
  const handler = statusReducerHandlers.get(action.type);

  return (handler && {
    ...handler({
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default statusReducer;
