import React, {ReactElement, useEffect} from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import setIsBodyScrolling from 'utils/helpers/setIsBodyScrolling';

import './styles.scss';

type ModalProps = {
  show: boolean;
  onHide?: () => void;
  className?: string;
  children?: ReactElement | null;
  footerElement?: ReactElement | null;
  styles?: Modal.Styles
};

export const ModalWindow = ({
  show,
  onHide,
  className,
  children,
  footerElement,
  styles
}: ModalProps): JSX.Element => {
  useEffect(() => {
    setIsBodyScrolling(!show);

    return () => setIsBodyScrolling(true);
  }, [show]);

  return <Modal
    isOpen={show}
    ariaHideApp={false}
    shouldCloseOnEsc
    className={cn('modal__window', className)}
    overlayClassName="modal__overlay"
    style={styles}
    onRequestClose={onHide}
  >
    <header className="modal__header">
      <button className={'modal__close-btn'} onClick={onHide}>x</button>
    </header>
    <div className="modal__body">
      {children}
    </div>
    <footer className="modal__footer">
      {footerElement}
    </footer>
  </Modal>;
};
