import {IUser} from '../auth/interfaces';
import axios from 'axios';

export function setAxiosHeaders(user: IUser, token: string): void {
  axios.defaults.headers.common.Authorization = token;
  axios.defaults.headers.common.API_KEY = process.env.REACT_APP_API_KEY;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  if (user && user['https://clever.com']) {
    axios.defaults.headers.common.CLEVER_TOKEN = user['https://clever.com'].cleverToken;
  }
}
