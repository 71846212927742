import React from 'react';
import cn from 'classnames';
import './styles.scss';
import {LibraryFilter} from '../LibraryEnums';

interface ISituationProps {
  title: string;
  id: number;
  serialNumber: string | undefined;
  checked?: boolean;
  onToggle: (serialNumber: string | undefined, id: number, checked: boolean, typeFilter: LibraryFilter) => void;
}

export const Situation = ({
  title,
  id,
  serialNumber,
  checked = false,
  onToggle
}: ISituationProps): JSX.Element => {
  const handleToggle = () => {
    onToggle(serialNumber, id, !checked, LibraryFilter.LifeSituations);
  };

  return <div
    className={cn('situation', {'situation--checked': checked})}
    role="presentation"
    onClick={handleToggle}><div className="situation__text">{title}</div>
  </div>;
};
