import {IAction, IErrorActionPayload} from 'redux/interface';
import {InferActionsTypes} from 'redux/helpers';

export const START_LOADING = 'START_LOADING';
export const startLoadingAction = (requestName: string): IAction<string> => ({
  type: START_LOADING,
  payload: requestName
});
export const STOP_LOADING = 'STOP_LOADING';
export const finishLoadingAction = (requestName: string): IAction<string> => ({
  type: STOP_LOADING,
  payload: requestName
});

export const SET_ERROR = 'SET_ERROR';
export const setErrorAction = (error: IErrorActionPayload): IAction<IErrorActionPayload> => ({
  type: SET_ERROR,
  payload: error
});
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const clearErrorAction = (key: string): IAction<string> => ({
  type: CLEAR_ERROR,
  payload: key
});

const statusActions = {
  startLoadingAction,
  finishLoadingAction,
  setErrorAction,
  clearErrorAction
};

export type StatusActionsType = InferActionsTypes<typeof statusActions>
