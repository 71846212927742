import React from 'react';
import {useIntl} from 'react-intl';

import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';

import './styles.scss';

type Props = {
  material: boolean;
  activeCard?: boolean;
  disabled?: boolean;
  addToMaterials: () => void;
}

export const MaterialButton = ({
  material,
  activeCard = false,
  disabled = false,
  addToMaterials
}: Props): JSX.Element => {
  const intl = useIntl();

  return <GritxButton
    title={material
      ? intl.formatMessage({
        id: 'gritx.common.card.saved',
        defaultMessage: 'Saved'
      })
      : intl.formatMessage({
        id: 'gritx.common.card.addToMaterials',
        defaultMessage: 'Add to Materials'
      })}
    className={`material ${activeCard ? 'material--active' : 'material--inactive'}`}
    variant={ButtonVariant.Primary}
    disabled={disabled}
    onClick={addToMaterials}
  />;
};
