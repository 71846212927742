import axios, {AxiosResponse} from 'axios';
import {
  IArticleAuthor,
  IArticleReference,
  IArticleReferenceDescr,
  IArticleSection,
  ITeiArticle
} from '../article/interfaces';

export const getTeiArticleApi = (id: string): Promise<AxiosResponse<ITeiArticle>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/page/${id}`);
};

export const getTeiArticleAuthorsApi = (id: string): Promise<AxiosResponse<IArticleAuthor[]>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/authors/${id}`);
};

export const getTeiReferenceAuthorsApi = (articleId: string, referenceId: string): Promise<AxiosResponse<IArticleAuthor[]>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/reference/authors/${articleId}/${referenceId}`);
};

export const getTeiArticleReferencesApi = (id: string): Promise<AxiosResponse<IArticleReferenceDescr[]>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/references/${id}`);
};

export const getTeiArticleSectionsApi = (id: string): Promise<AxiosResponse<IArticleSection[]>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/sections/${id}`);
};

export const getTeiArticleReferenceApi = (articleId: string, referenceId: string): Promise<AxiosResponse<IArticleReference>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/reference/${articleId}/${referenceId}`);
};

export const getTeiSectionSectionApi = (articleId: string, sectionIndex: string): Promise<AxiosResponse<IArticleSection>> => {
  return axios.get(`${process.env.REACT_APP_API_URL}/article/section/${articleId}/${sectionIndex}`);
};
