import React from 'react';

import {IArticle} from 'redux/content/interfaces';
import Loader from 'components/loader';

import './styles.scss';
import {FormattedMessage} from 'react-intl';

interface IPreviewPage {
  journal: IArticle
}

export const PreviewPage = ({
  journal
}: IPreviewPage): JSX.Element => {
  if (!journal) {
    return <Loader/>;
  }

  return <div className="journal">
    <div className="journal__page">
      {
        journal.pages.map((item, index) => {
          return <div key={item.id}>
            <p className={'journal__page-number'}>
              <FormattedMessage
                id={'gritx.library.page'}
                defaultMessage={'Page {page}'}
                values={{page: index + 1}}
              />
            </p>
            <div
              className={'journal__question--preview'}
              dangerouslySetInnerHTML={{
                __html: item.prompt || ''
              }}
            />
            <p className={'journal__answer--preview'}>{item.text || ''}</p>
          </div>;
        })
      }
    </div>
  </div>;
};
