import {IFeedbackActionHandler, IFeedbackInitialState, IFeedbackPayload} from './interfaces';
import {CLEAR_ATTACHMENT, FeedbackActionsType, REMOVE_ATTACHMENT, UPLOADED_ATTACHMENT} from './actions';

const ContentInitialState: IFeedbackInitialState = {};

const setAttachKey = ({
  state,
  payload
}: IFeedbackActionHandler): IFeedbackInitialState => {
  const data = payload as IFeedbackPayload;

  return {
    ...state,
    [data.key]: data.attachKey
  };
};
const removeAttachKey = ({
  state,
  payload
}: IFeedbackActionHandler): IFeedbackInitialState => {
  const newSate = {...state};

  delete newSate[payload as string];

  return {...newSate};
};
const clearAttach = (): IFeedbackInitialState => {
  return {};
};

const statusReducerHandlers = new Map([
  [UPLOADED_ATTACHMENT, setAttachKey],
  [REMOVE_ATTACHMENT, removeAttachKey],
  [CLEAR_ATTACHMENT, clearAttach]
]);

const feedbackReducer = (state = ContentInitialState, action: FeedbackActionsType): IFeedbackInitialState => {
  const handler = statusReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IFeedbackActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default feedbackReducer;
