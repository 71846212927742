import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {getLibContent, getLibFilters, getTranslations, libraryAsyncActions} from 'redux/pageAsyncActions';
import {IFilter} from 'redux/content/interfaces';
import {maxArticleCards} from 'utils/constants/constants';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import CrossroadImg from 'assets/image/library/skill-crossroad.svg';
import {ReactComponent as BookIcon} from 'assets/image/library/book.svg';

import CardSection from 'components/card-section';
import Loader from 'components/loader';
import LibraryFilters from 'components/library';
import NotFoundInfo from 'components/not-found-info';

import './styles.scss';

export const Library: React.FunctionComponent = () => {
  const {
    content: {
      content,
      filters: libFilters
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useNavigate();
  const intl = useIntl();
  const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([]);

  useGetTranslations({
    translationKey: TranslationKeys.Library
  });

  useEffect(() => {
    dispatch(libraryAsyncActions[getLibFilters]());
  }, []);

  function loadContent({
    filters,
    offset
  }: { filters: IFilter[]; offset: number }) {
    dispatch(libraryAsyncActions[getLibContent](
      {
        filters,
        offset,
        pageSize: maxArticleCards
      }
    ));
  }

  function handleLoadMore(offset: number) {
    loadContent({
      filters: selectedFilters,
      offset
    });
  }

  function handleChangeFilters(changedFilters: IFilter[]) {
    setSelectedFilters(changedFilters);
    loadContent({
      filters: changedFilters,
      offset: 0
    });
  }

  function handleOpenCard(cardId: number) {
    history(`/content#${cardId}`);
    // history.push(
    //   `/content#${cardId}`,
    //   history.location.state ? history.location.state : {parentLocation: history.location.pathname}
    // );
  }

  function renderFilters() {
    if (libFilters) {
      return <LibraryFilters filters={libFilters} onChange={handleChangeFilters}/>;
    }

    return null;
  }

  return <section className={'library container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="library__title"><BookIcon className="library__icon"/>
          <FormattedMessage
            id={'gritx.library.title'}
            defaultMessage={'library'}
          />
        </h1>
        <div className="library__filters">
          {
            loading.includes(getLibFilters) ? <Loader/> : renderFilters()
          }
        </div>
        <div className="library__cards">
          {content.length === 0 && libFilters ? <NotFoundInfo
            title={intl.formatMessage({
              id: 'gritx.library.sectionCards.notFound',
              defaultMessage: 'Sorry, but nothing can be found. Try the search or apply fewer filters.'
            })}
            image={CrossroadImg}
          /> : <CardSection
            showLoader={loading.includes(getLibContent)}
            cards={content}
            onLoadMore={handleLoadMore}
            onClickCard={handleOpenCard}
          />}
        </div>
      </>
    }
  </section>;
};
