import {IOverlaySuggestion} from '@wholesalechange/chatcomponent';
import {errorHandler} from '../../redux/errorHandler';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {IUseDictionary} from '@wholesalechange/chatcomponent';

function getHeaders(token: string): { Authorization: string, API_KEY: string | undefined } {
  return {
    Authorization: token,
    API_KEY: process.env.REACT_APP_API_KEY
  };
}

export const useDictionary = (): IUseDictionary => {
  const {
    auth: {
      auth0Client
    }
  } = useSelector((store: IStore) => store);
  const dispatch = useDispatch();

  async function getToken(): Promise<string> {
    try {
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        return await auth0Client.getTokenSilently();
      }
    } catch (e) {
      console.log('useDictionary', e);
      dispatch(errorHandler({
        toastKey: ToastKey.GetToken,
        actionKey: 'useDictionary getToken',
        error: e
      }));
    }

    return '';
  }

  async function getAutocompleteDictionary(serviceUrl: string, params: { [key: string]: string}): Promise<IOverlaySuggestion[]> {
    const token = await getToken();
    const config = {
      params: params,
      headers: getHeaders(token)
    };

    const {data: response} = await axios.get(
      serviceUrl,
      config
    );

    return response || [];
  }

  return {
    getAutocompleteDictionary
  };
};
