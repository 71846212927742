import React, {useEffect} from 'react';

import {
  getBotAvatars,
  getUserAvatars,
  updateUserProfileAction
} from 'redux/auth/authAsyncActions';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from 'redux/interface';
import {IAvatar} from 'redux/auth/interfaces';

import {Avatar} from 'components/avatar/Avatar';
import Loader from 'components/loader';

import './styles.scss';

interface IAvatarListProps {
  type: null | 'user' | 'bot';
  closeModal: (props: null) => void;
}

export const AvatarList = ({
  type,
  closeModal
}: IAvatarListProps): JSX.Element => {
  const {
    auth: {userAvatars, botAvatars, userProfile}
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'user') {
      dispatch(getUserAvatars());
    }
    if (type === 'bot') {
      dispatch(getBotAvatars());
    }
  }, []);

  function handleChangeAvatar(fileId: number) {
    return () => {
      if (type === 'user' && userProfile) {
        dispatch(
          updateUserProfileAction({
            ...userProfile,
            userAvatarId: fileId
          })
        );

        closeModal(null);

        return;
      }
      if (type === 'bot' && userProfile) {
        dispatch(
          updateUserProfileAction({
            ...userProfile,
            dialogAvatarId: fileId
          })
        );

        closeModal(null);

        return;
      }
      console.log('default click action');
    };
  }

  function renderAvatars() {
    const avatars: IAvatar[] | null = type === 'bot' ? botAvatars : userAvatars;

    return avatars && avatars.length && avatars.filter(item => item.fileUrl).length === avatars.length
      ? <div className="avatars__content">
        {avatars.map((item, index) => {
          return (
            <Avatar
              key={index}
              imgUrl={item.fileUrl}
              imgText={item.text}
              handleClick={handleChangeAvatar(item.fileId)}
            />
          );
        })}
      </div>
      : <Loader nested/>;
  }

  return (
    <>
      {renderAvatars()}
    </>
  );
};
