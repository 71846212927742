import './styles.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useGetTranslations} from '../../utils/hooks/useGetTranslations';
import {TranslationKeys} from '../../utils/constants/routeConstants';
import {
  articleAsyncActions, getTeiArticleReference
} from '../../redux/pageAsyncActions';
import {closeArticleReference} from '../../redux/article/actions';
import Loader from '../../components/loader';
import {doiUrl} from '../../utils/constants/constants';

export const ArticleReference: React.FunctionComponent = () => {
  const {
    article: {
      reference
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {articleId, referenceId} = useParams();

  useGetTranslations({
    translationKey: TranslationKeys.Article
  });

  useEffect(() => {
    dispatch(closeArticleReference());
    if (articleId && referenceId) {
      dispatch(articleAsyncActions[getTeiArticleReference](articleId, referenceId));
    }
  }, [articleId, referenceId]);

  return <div>
    {!reference && loading.includes(getTeiArticleReference) && <Loader className="reference-page-loader"/>}
    {reference && <div className="reference-page">
      <div className="reference-page-breadcrumbs">
        {<div className="reference-page-breadcrumbs-item">
          <Link to={`/article/${reference.articleId}`}>
            <FormattedMessage
              id={'gritx.article.article'}
              defaultMessage={'Article'}
            />
          </Link>
        </div>}
        {reference && <div className="reference-page-breadcrumbs-item"><Link
          to={`/article/references/${reference.articleId}`}>
          <FormattedMessage
            id={'gritx.article.references'}
            defaultMessage={'References'}
          />
        </Link></div>}
      </div>
      <div className="reference-page-header">
        <div className="reference-page-header__title">{reference.title}</div>
      </div>
      <div className="reference-page-line">
        {reference.authors && <Link to={`/article/reference/authors/${reference.articleId}/${reference.id}`}>
          {reference.authors}
        </Link>}
        {reference.date && <div><span className="reference-page-label"><FormattedMessage
          id={'gritx.article.firstPublished'}
          defaultMessage={'First published'}
        />: </span>{reference.date}</div>}
      </div>
      <div className="reference-page-line">
        <div>{reference.publicationTitle}</div>
        {reference.doi && <div><a href={`${doiUrl}${reference.doi}`} target="_blank"
          rel="noreferrer">{doiUrl}{reference.doi}</a></div>}
      </div>
      <div className="reference-page-line">
        <div><span className="reference-page-label"><FormattedMessage
          id={'gritx.article.references'}
          defaultMessage={'References'}
        />: </span>{reference.referenceCount}</div>
      </div>
    </div>}
  </div>;
};
