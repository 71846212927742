import React, {useEffect} from 'react';
import {FileWithPath, useDropzone} from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

import {fileSize} from 'utils/constants/constants';

import './styles.scss';

interface IFileUploader {
  label?: string
  onChange: (files: readonly FileWithPath[]) => void
}

export const FileUploader: React.FunctionComponent<IFileUploader> = ({
  label,
  onChange
}: IFileUploader) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxSize: fileSize,
    accept: {'image/jpeg': [], 'image/png': [], 'application/pdf': []}
  });

  useEffect(() => {
    onChange(acceptedFiles);
  }, [acceptedFiles.length]);

  return <div className="file-uploader">
    <p className="file-uploader__title">
      {label}
    </p>
    <div {...getRootProps({
      className: cn('dropzone',
        {
          'dropzone--active': isDragActive,
          'dropzone--accept': isDragAccept,
          'dropzone--reject': isDragReject
        })
    })}>
      <input {...getInputProps({
        multiple: false
      })} />
      <div className="file-uploader__button">
        <FormattedMessage
          id={'gritx.common.button.browse'}
          defaultMessage={'Browse'}
        />
      </div>
    </div>
    {
      Boolean(fileRejections.length)
      && <span className={'file-uploader__error'}>
        <FormattedMessage
          id={'gritx.common.errors.maxSize'}
          defaultMessage={'Maximum attachment size ({maxSize}) is exceeded'}
          values={{maxSize: '10MB'}}
        />
      </span>
    }
  </div>;
};
