import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useDispatch, useSelector} from 'react-redux';
import {IFilter, ILibraryFiltersResponse} from 'redux/content/interfaces';
import {IStore} from 'redux/interface';
import {setResourceSelectedFilters} from 'redux/content/actions';

import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';

import './styles.scss';
import FilterBar from '../../components/library/filter-bar';
import Categories from './categories';
import Category from './category';
import {FilterModel, FilterValuesModel} from '../../components/library/LibraryModels';
import {LibraryFilter} from '../../components/library/LibraryEnums';
import {resourceContentTypeId} from '../../utils/constants/constants';

interface IResourceFilters {
  filters: ILibraryFiltersResponse | null
  onChange: (filters: IFilter[]) => void
}

export const ResourceFilters: FunctionComponent<IResourceFilters> = ({
  filters,
  onChange
}: IResourceFilters) => {
  const {
    content: {
      resourceSelectedFilters
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [categoryFilterList, setCategoryFilterList] = useState<FilterModel>();

  function getFilters(typeFilter: LibraryFilter): FilterModel {
    const filterList = filters?.filters?.find(a => a.name === typeFilter);

    return {
      serialNumber: filterList?.serialNumber,
      values: filterList?.allowValues as FilterValuesModel[]
    };
  }

  function dispatchFilters(filterList: IFilter[]) {
    dispatch(setResourceSelectedFilters(filterList));
  }

  function changeCategoryFilter(id: number, checked: boolean) {
    const updatedCategories = Object.assign({}, categoryFilterList);

    updatedCategories.values = updatedCategories?.values.map(sv => {
      if (sv.id === id) {
        return {...sv, checked};
      }

      return sv;
    });
    setCategoryFilterList(updatedCategories);
  }

  useEffect(() => {
    const filterList = getFilters(LibraryFilter.Categories);

    if (!resourceSelectedFilters.length) {
      setCategoryFilterList(filterList);

      const filtersList = filters?.filters.filter(f => f.name !== LibraryFilter.ReadingTime)
        .map((filter) => {
          return {
            serialNumber: filter.serialNumber,
            name: filter.name,
            format: filter.format,
            inputValue: filter.name === LibraryFilter.Types ? [resourceContentTypeId] : []
          };
        });

      if (filtersList) {
        onChange(filtersList);
        dispatchFilters(filtersList);
      }
    } else {
      const categoryValues = resourceSelectedFilters.find(a => a.name === LibraryFilter.Categories)?.inputValue as number [];
      const mappedFilterValues = filterList.values?.map((val: FilterValuesModel) => {
        if (categoryValues && categoryValues.find(cv => cv === val.id)) {
          return {...val, checked: true};
        }

        return val;
      });

      filterList.values = mappedFilterValues;
      setCategoryFilterList(filterList);
    }
  }, [filters]);

  const handleToggle = (serialNumber: string | undefined, id: number, checked: boolean, filterType: LibraryFilter) => {
    if (filterType === LibraryFilter.Categories) {
      changeCategoryFilter(id, checked);
    }

    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.serialNumber === serialNumber);

    if (checked) {
      updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue, id] as number[];
    } else {
      updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue].filter(item => item !== id) as number[];
    }
    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
  };

  const handleChangeSearchString = (value: string) => {
    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.SearchString);

    updatedFilters[filterIdx].inputValue = value;
    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
  };

  const handleClear = () => {
    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.Categories);

    updatedFilters[filterIdx].inputValue = [];

    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
    const updatedCategories = Object.assign({}, categoryFilterList);

    updatedCategories.values = updatedCategories?.values.map(sv => {
      return {...sv, checked: false};
    });
    setCategoryFilterList(updatedCategories);
  };

  return <div className="resource-filters">
    <Categories>
      <>
        <div className="resource-filters__categories">
          {categoryFilterList?.values?.map(v => {
            return (
              <Category
                title={v.name}
                key={v.id}
                serialNumber={categoryFilterList.serialNumber}
                id={v.id}
                checked={v.checked}
                onToggle={handleToggle}/>
            );
          })}
        </div>
        <div className="resource-filters__button">
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.resource.clear-category',
              defaultMessage: 'Clear categories'
            })}
            variant={ButtonVariant.Outline}
            onClick={handleClear}
          />
        </div>
      </>
    </Categories>
    <FilterBar
      selectedFilters={resourceSelectedFilters}
      onToggle={handleToggle}
      onChangeSearchString={handleChangeSearchString}
    />
  </div>;
};
