import React, {FunctionComponent} from 'react';
import {IArticleSection} from '../../redux/article/interfaces';
import './styles.scss';

interface ISection {
  section: IArticleSection
}

export const Section: FunctionComponent<ISection> = ({section}: ISection) => {
  function splitByNewLine(text: string) {
    return text.split('\n')
      .map((t, i) => <p key={i}>{t}</p>);
  }

  return <div className="section">
    <div className="section-header">
      <h2 className="section-header__title">{section.number} {section.title}</h2>
    </div>
    <div className="section-body">
      <div className="section-body-text">{splitByNewLine(section.text)}</div>
    </div>
  </div>;
};
