import {IArticleAuthor, IArticleReference, IArticleReferenceDescr, IArticleSection, ITeiArticle} from './interfaces';
import {IAction} from '../interface';
import {InferActionsTypes} from '../helpers';

export const TEI_ARTICLE_LOADED = 'TEI_ARTICLE_LOADED';
export const teiArticleLoadedAction = (article: ITeiArticle): IAction<ITeiArticle> => ({
  type: TEI_ARTICLE_LOADED,
  payload: article
});

export const TEI_ARTICLE_AUTHORS_LOADED = 'TEI_ARTICLE_AUTHORS_LOADED';
export const teiArticleAuthorsLoadedAction = (authors: IArticleAuthor[]): IAction<IArticleAuthor[]> => ({
  type: TEI_ARTICLE_AUTHORS_LOADED,
  payload: authors
});

export const TEI_ARTICLE_REFERENCES_LOADED = 'TEI_ARTICLE_REFERENCES_LOADED';
export const teiArticleReferencesLoadedAction = (references: IArticleReferenceDescr[]): IAction<IArticleReferenceDescr[]> => ({
  type: TEI_ARTICLE_REFERENCES_LOADED,
  payload: references
});

export const TEI_ARTICLE_REFERENCE_LOADED = 'TEI_ARTICLE_REFERENCE_LOADED';
export const teiArticleReferenceLoadedAction = (reference: IArticleReference): IAction<IArticleReference> => ({
  type: TEI_ARTICLE_REFERENCE_LOADED,
  payload: reference
});

export const TEI_ARTICLE_SECTION_LOADED = 'TEI_ARTICLE_SECTION_LOADED';
export const teiArticleSectionLoadedAction = (section: IArticleSection): IAction<IArticleSection> => ({
  type: TEI_ARTICLE_SECTION_LOADED,
  payload: section
});

export const TEI_ARTICLE_SECTIONS_LOADED = 'TEI_ARTICLE_SECTIONS_LOADED';
export const teiArticleSectionsLoadedAction = (sections: IArticleSection[]): IAction<IArticleSection[]> => ({
  type: TEI_ARTICLE_SECTIONS_LOADED,
  payload: sections
});

export const CLOSE_ARTICLE = 'CLOSE_ARTICLE';
export const closeArticle = (): IAction<null> => ({
  type: CLOSE_ARTICLE,
  payload: null
});

export const CLOSE_REFERENCE = 'CLOSE_REFERENCE';
export const closeArticleReference = (): IAction<null> => ({
  type: CLOSE_REFERENCE,
  payload: null
});

export const CLOSE_AUTHORS = 'CLOSE_AUTHORS';
export const closeArticleAuthors = (): IAction<null> => ({
  type: CLOSE_AUTHORS,
  payload: null
});

export const CLOSE_SECTION = 'CLOSE_SECTION';
export const closeArticleSection = (): IAction<null> => ({
  type: CLOSE_SECTION,
  payload: null
});

const articleActions = {
  teiArticleLoadedAction,
  teiArticleAuthorsLoadedAction,
  teiArticleReferencesLoadedAction,
  teiArticleSectionLoadedAction,
  teiArticleSectionsLoadedAction,
  closeArticle,
  closeArticleReference,
  closeArticleAuthors,
  closeArticleSection
};

export type ArticleActionsType = InferActionsTypes<typeof articleActions>
