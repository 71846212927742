import React from 'react';

import {ReactComponent as PencilImg} from 'assets/image/user-settings/pencil-ico.svg';

import './styles.scss';

export interface IAvatarProps {
  imgUrl?: string;
  imgText?: string
  editable?: boolean,
  handleClick?: () => void;
}

export const Avatar = ({
  handleClick,
  editable = false,
  imgUrl,
  imgText
}: IAvatarProps): JSX.Element => {
  return (
    <>
      {editable
        ? (<div className="avatars">
          <div className="avatars__item">
            <img className="avatars__img" src={imgUrl} alt={imgText}/>
          </div>
          <button className="avatars__btn" onClick={handleClick}>
            <PencilImg className="avatars__btn-icon"/>
          </button>
        </div>)
        : (<div className="avatars">
          <button className="avatars__item" onClick={handleClick}>
            <img className="avatars__img" src={imgUrl} alt={imgText}/>
          </button>
        </div>)
      }
    </>
  );
};
