import {IUpdateTranslations} from './interfaces';
import {IAction} from 'redux/interface';
import {InferActionsTypes} from '../helpers';

export const UPDATE_TRANSLATIONS = 'UPDATE_TRANSLATIONS';
export const updateTranslationsAction = (payload: IUpdateTranslations): IAction<IUpdateTranslations> => ({
  type: UPDATE_TRANSLATIONS,
  payload
});

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const changeLocaleAction = (locale: string): IAction<string> => ({
  type: CHANGE_LOCALE,
  payload: locale
});

const translationActions = {
  updateTranslationsAction,
  changeLocaleAction
};

export type TranslationActionsType = InferActionsTypes<typeof translationActions>
