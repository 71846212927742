
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {IDialogueCard} from '../../redux/dialogue/interfaces';
import './styles.scss';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import {useIntl} from 'react-intl';
import {
  addBotToDialogue, addUsersToDialogue,
  blockParticipant,
  dialogueAsyncActions, getArticle,
  getDialogueParticipants,
  removeParticipantFromDialogue, unlockParticipant
} from '../../redux/pageAsyncActions';
import {ParticipantCard} from './ParticipantCard';
import {ModalWindow} from '../modal-window/ModalWindow';
import BotList from '../bot-list';
import {UsersAddInput} from '../users-add-input/UsersAddInput';
import Loader from '../loader';

interface IDialogueParticipants {
  dialogue: IDialogueCard | null | undefined,
  isShowParticipants: boolean
  onAddBot: () => void
}

export const DialogueParticipants: FunctionComponent<IDialogueParticipants> = ({dialogue, isShowParticipants, onAddBot}: IDialogueParticipants) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    dialogue: {participants},
    status: {loading}
  } = useSelector((state: IStore) => state);

  const [isShowBotsDialogue, setShowBotsDialogue] = useState<boolean>(false);
  const [isShowAddUsersInput, setShowAddUsersInput] = useState<boolean>(false);

  useEffect(() => {
    if (dialogue) {
      dispatch(dialogueAsyncActions[getDialogueParticipants](dialogue.id));
    }
  }, [dialogue]);

  function handleAddUser() {
    setShowAddUsersInput(!isShowAddUsersInput);
  }

  function handleCloseBotsDialogue() {
    onAddBot();
    setShowBotsDialogue(false);
  }

  function handleCloseAddUsersInput() {
    setShowAddUsersInput(false);
  }

  function handleOpenBotsDialogue() {
    setShowBotsDialogue(true);
  }

  function handleRemoveParticipant(participantId: number) {
    if (!dialogue) {
      return;
    }
    dispatch(dialogueAsyncActions[removeParticipantFromDialogue](participantId, dialogue.id));
  }

  function handleBlockParticipant(participantId: number) {
    if (!dialogue) {
      return;
    }
    dispatch(dialogueAsyncActions[blockParticipant](participantId, dialogue.id));
  }

  function handleUnlockParticipant(participantId: number) {
    if (!dialogue) {
      return;
    }
    dispatch(dialogueAsyncActions[unlockParticipant](participantId, dialogue.id));
  }

  return <>
    {dialogue && isShowParticipants
      ? <div className="participants">
        <div className="participants__header">
          <div className="participants__title">
            <GritxButton
              title={intl.formatMessage({
                id: 'gritx.participants.addUser',
                defaultMessage: 'Add a user'
              })}
              variant={ButtonVariant.Primary}
              className="participants__button"
              onClick={handleAddUser}
            />
            <GritxButton
              title={intl.formatMessage({
                id: 'gritx.participants.addBot',
                defaultMessage: 'Add a bot'
              })}
              variant={ButtonVariant.Primary}
              className="participants__button"
              onClick={handleOpenBotsDialogue}
            />
          </div>
          <div className="participants__body">
            {isShowAddUsersInput
              ? <UsersAddInput dialogueId={dialogue.id} onClose={handleCloseAddUsersInput}/>
              : <></>
            }
            <ul className="participants__list">
              {
                participants.sort((firstItem, secondItem) => firstItem.participantId - secondItem.participantId).map((participant, i) => {
                  return <li key={participant.participantId} className="participants__item">
                    <ParticipantCard participant={participant}
                      onRemove={handleRemoveParticipant} onBlock={handleBlockParticipant} onUnlock={handleUnlockParticipant}/>
                  </li>;
                })
              }
            </ul>
            {(loading.includes(addBotToDialogue) || loading.includes(addUsersToDialogue)) && <Loader/>}
          </div>
        </div>

        <ModalWindow
          className="participants__modal"
          show={isShowBotsDialogue}
          onHide={handleCloseBotsDialogue}>
          <BotList
            dialogueId={dialogue.id}
            closeModal={handleCloseBotsDialogue}
          />
        </ModalWindow>
      </div>
      : <></>
    }
  </>;
};
