import axios, {AxiosResponse} from 'axios';
import {IFeedbackAttachInfo} from 'redux/feedback/interfaces';
import {INewsletterFormModel, IPostFormModel} from 'components/feedback-forms/interfaces';

export const uploadFeedbackAttachApi = (contentData: string | ArrayBuffer | null, fileName: string): Promise<AxiosResponse<IFeedbackAttachInfo>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mail/upload`,
    {
      fileBody: contentData,
      fileName
    },
    {
      headers: {
        Accept: 'application/json',
        API_KEY: process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    }
  );
};
export const postFeedbackApi = (formData: IPostFormModel): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mail/inner/send`,
    formData,
    {
      headers: {
        Accept: 'application/json',
        API_KEY: process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    }
  );
};
export const postMessageApi = (formData: INewsletterFormModel): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/mail/send`,
    formData,
    {
      headers: {
        Accept: 'application/json',
        API_KEY: process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    }
  );
};
