import {IUserProfile} from '@wholesalechange/chatcomponent';
import {errorHandler} from '../../redux/errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {useDispatch} from 'react-redux';
import {changeLocale} from '../../redux/translation/translationAsyncActions';
import {libClearSelectedFiltersAction} from '../../redux/content/actions';
import {updateUserProfileAction} from '../../redux/auth/authAsyncActions';
import {getMainDialogueApi, setDialogueLanguageApi} from '../../redux/api/dialogue';

interface IUseChangeLanguage {
  changeLanguage: (userProfile: IUserProfile, language: string) => void
}

export const useChangeLanguage = (): IUseChangeLanguage => {
  const dispatch = useDispatch();

  async function setMainDialogLanguage(language: string) {
    try {
      const {data} = await getMainDialogueApi();

      if (data != null) {
        await setDialogueLanguageApi(data.id, language);
      }

      return data;
    } catch (e) {
      console.log('useChangeLanguage setMainDialogLanguage', e);
      dispatch(errorHandler({
        toastKey: ToastKey.SetDialogLanguage,
        actionKey: 'useChangeLanguage setMainDialogLanguage',
        error: e
      }));

      return null;
    }
  }

  async function changeLanguage(userProfile: IUserProfile, language: string) {
    setMainDialogLanguage(language);
    dispatch(changeLocale(language));
    dispatch(libClearSelectedFiltersAction());
    dispatch(updateUserProfileAction({
      ...userProfile,
      language: language
    } as IUserProfile));
  }

  return {
    changeLanguage
  };
};
