import './styles.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useGetTranslations} from '../../utils/hooks/useGetTranslations';
import {TranslationKeys} from '../../utils/constants/routeConstants';
import {
  articleAsyncActions,
  getTeiArticle
} from '../../redux/pageAsyncActions';
import './styles.scss';
import {closeArticle} from '../../redux/article/actions';
import Loader from '../../components/loader';
import {doiUrl} from '../../utils/constants/constants';

export const ArticlePage: React.FunctionComponent = () => {
  const {
    article: {
      article
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {articleId} = useParams();

  useGetTranslations({
    translationKey: TranslationKeys.Article
  });

  useEffect(() => {
    if (articleId) {
      if (article && article.id.toString() !== articleId) {
        dispatch(closeArticle());
      }
      dispatch(articleAsyncActions[getTeiArticle](articleId));
    }
  }, [articleId]);

  return <div>
    {!article && loading.includes(getTeiArticle) && <Loader className="article-page-loader"/>}
    {article && <div className="article-page">
      <div className="article-page-header"><h2 className="article-page-header__title">{article.title}</h2></div>
      <div className="article-page-line">
        <div><Link to={`/article/authors/${article.id}`}>{article.authors}</Link></div>
        <div><span className="article-page-label">
          <FormattedMessage
            id={'gritx.article.firstPublished'}
            defaultMessage={'First published'}
          />: </span>{article.date}</div>
      </div>
      <div className="article-page-line">
        <div><p>{article.publicationTitle}</p></div>
        {article.doi && <div><a href={`${doiUrl}${article.doi}`} target="_blank"
          rel="noreferrer">{doiUrl}{article.doi}</a></div>}
      </div>
      <div className="article-page-line">
        <div><span className="article-page-label"><FormattedMessage
          id={'gritx.article.references'}
          defaultMessage={'References'}
        />: </span><Link
          to={`/article/references/${article.id}`}>{article.referenceCount}</Link></div>
      </div>
      <div className="article-page-line">
        <div><span className="article-page-label"><FormattedMessage
          id={'gritx.article.summary'}
          defaultMessage={'Summary'}
        />: </span><p>{article.summary}</p></div>
      </div>
      <div className="article-page-line">
        {article.keywords && <div><FormattedMessage
          id={'gritx.article.keywords'}
          defaultMessage={'Keywords'}
        />: {article.keywords}</div>}
      </div>
      <div className="article-page-view-all">
        <Link to={`/article/sections/${article.id}`}>
          <FormattedMessage
            id={'gritx.article.viewAll'}
            defaultMessage={'View all'}
          />
        </Link>
      </div>
      <div className="article-page-sections">
        <div className="article-page-sections-header">
          <div className="article-page-sections-header-title">
            <FormattedMessage
              id={'gritx.article.tableOfContents'}
              defaultMessage={'Table of contents'}
            />:</div>
        </div>
        {article.sections.map((section, i) => {
          return <div className="article-page-section" key={i}>
            <div><Link to={`/article/section/${article.id}/${i}`}>{section.number} {section.title}</Link></div>
          </div>;
        })}
      </div>
    </div>}
  </div>;
};
