import {IAction} from 'redux/interface';
import {IFeedbackPayload} from './interfaces';
import {InferActionsTypes} from '../helpers';

export const UPLOADED_ATTACHMENT = 'UPLOADED_ATTACHMENT';
export const attachmentUploadedAction = (attach: IFeedbackPayload): IAction<IFeedbackPayload> => ({
  type: UPLOADED_ATTACHMENT,
  payload: attach
});

export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const removeAttachAction = (key: number): IAction<number> => ({
  type: REMOVE_ATTACHMENT,
  payload: key
});
export const CLEAR_ATTACHMENT = 'CLEAR_ATTACHMENT';
export const clearAttachAction = (): IAction<null> => ({
  type: CLEAR_ATTACHMENT,
  payload: null
});

const feedbackActions = {
  attachmentUploadedAction,
  removeAttachAction,
  clearAttachAction
};

export type FeedbackActionsType = InferActionsTypes<typeof feedbackActions>
