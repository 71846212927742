export default function setIsBodyScrolling(state: boolean): void {
  const body = document.querySelector('body');

  if (body) {
    if (!state) {
      body.classList
        .add('stop-scrolling');
    } else {
      body
        .classList
        .remove('stop-scrolling');
    }
  }
}
