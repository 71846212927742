import {ThunkAction} from 'redux-thunk';
import {IStore} from '../interface';
import {Action} from 'redux';
import {
  getTeiArticle,
  getTeiArticleAuthors,
  getTeiArticleReference,
  getTeiArticleReferences,
  getTeiArticleSection,
  getTeiReferenceAuthors,
  getTeiArticleSections
} from '../pageAsyncActions';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from '../status/actions';
import {errorHandler} from '../errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {
  teiArticleAuthorsLoadedAction,
  teiArticleLoadedAction,
  teiArticleReferenceLoadedAction,
  teiArticleReferencesLoadedAction,
  teiArticleSectionLoadedAction,
  teiArticleSectionsLoadedAction
} from './actions';
import {
  getTeiArticleApi,
  getTeiArticleAuthorsApi,
  getTeiArticleReferenceApi,
  getTeiArticleReferencesApi,
  getTeiArticleSectionsApi,
  getTeiReferenceAuthorsApi,
  getTeiSectionSectionApi
} from '../api/article';

export const loadTeiArticleAction = (id: string): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiArticle;

  if (isAuthenticated && id) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiArticleApi(id);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleLoadedAction(data));
    } catch (e) {
      console.log('loadTeiArticleAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiArticleAction,
        actionKey,
        error: e,
        currentAction: loadTeiArticleAction(id)
      }));
    }
  }
});

export const loadTeiArticleAuthorsAction = (id: string): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiArticleAuthors;

  if (isAuthenticated && id) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiArticleAuthorsApi(id);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleAuthorsLoadedAction(data));
    } catch (e) {
      console.log('loadTeiArticleAuthorsAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiArticleAuthorsAction,
        actionKey,
        error: e,
        currentAction: loadTeiArticleAuthorsAction(id)
      }));
    }
  }
});

export const loadTeiReferenceAuthorsAction = (articleId: string, referenceId: string):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiReferenceAuthors;

  if (isAuthenticated && articleId && referenceId) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiReferenceAuthorsApi(articleId, referenceId);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleAuthorsLoadedAction(data));
    } catch (e) {
      console.log('loadTeiReferenceAuthorsAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiReferenceAuthorsAction,
        actionKey,
        error: e,
        currentAction: loadTeiReferenceAuthorsAction(articleId, referenceId)
      }));
    }
  }
});

export const loadTeiArticleSectionAction = (articleId: string, sectionIndex: string):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiArticleSection;

  if (isAuthenticated && articleId && sectionIndex) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiSectionSectionApi(articleId, sectionIndex);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleSectionLoadedAction(data));
    } catch (e) {
      console.log('loadTeiArticleSectionAction', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiArticleSectionAction,
        actionKey,
        error: e,
        currentAction: loadTeiArticleSectionAction(articleId, sectionIndex)
      }));
    }
  }
});

export const loadTeiArticleReferencesAction = (id: string): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiArticleReferences;

  if (isAuthenticated && id) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiArticleReferencesApi(id);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleReferencesLoadedAction(data));
    } catch (e) {
      console.log('getTeiArticleReferences', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiArticleReferencesAction,
        actionKey,
        error: e,
        currentAction: loadTeiArticleReferencesAction(id)
      }));
    }
  }
});

export const loadTeiArticleSectionsAction = (id: string): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiArticleSections;

  if (isAuthenticated && id) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiArticleSectionsApi(id);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleSectionsLoadedAction(data));
    } catch (e) {
      console.log('getTeiArticleSections', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiArticleSectionsAction,
        actionKey,
        error: e,
        currentAction: loadTeiArticleSectionsAction(id)
      }));
    }
  }
});

export const loadTeiArticleReferenceAction = (articleId: string, referenceId: string):
  ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {
      auth0Client
    }
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getTeiArticleReference;

  if (isAuthenticated && articleId && referenceId) {
    dispatch(startLoadingAction(actionKey));

    try {
      const {data} = await getTeiArticleReferenceApi(articleId, referenceId);

      dispatch(finishLoadingAction(actionKey));
      dispatch(clearErrorAction(actionKey));

      dispatch(teiArticleReferenceLoadedAction(data));
    } catch (e) {
      console.log('getTeiArticleReference', e.message);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadTeiArticleReferencesAction,
        actionKey,
        error: e,
        currentAction: loadTeiArticleReferenceAction(articleId, referenceId)
      }));
    }
  }
});
