import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {IStore} from 'redux/interface';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from 'redux/status/actions';
import {sendFeedback} from 'redux/pageAsyncActions';
import {errorHandler} from 'redux/errorHandler';
import {toBase64} from 'utils/helpers/base64convert';

import {INewsletterFormModel, IPostFormModel} from 'components/feedback-forms/interfaces';

import {IFeedbackAttachInfo} from './interfaces';
import {attachmentUploadedAction, clearAttachAction} from './actions';
import {postFeedbackApi, postMessageApi, uploadFeedbackAttachApi} from 'redux/api/feedback';
import {ToastKey} from 'components/gritx-toast/ToastKeyEnum';

export const uploadAttachAction = (file: { key: number, file: File }): ThunkAction<void, IStore, unknown, Action> => (async (dispatch) => {
  const actionKey = file.key.toString();

  dispatch(startLoadingAction(actionKey));
  try {
    const contentData = await toBase64(file.file);

    const {data: contentInfo} = await uploadFeedbackAttachApi(contentData, file.file.name);

    dispatch(clearErrorAction(actionKey));
    dispatch(finishLoadingAction(actionKey));
    dispatch(attachmentUploadedAction({
      key: file.key,
      attachKey: contentInfo as IFeedbackAttachInfo
    }));
  } catch (e) {
    console.log('uploadAttachAction', e);
    dispatch(errorHandler({
      toastKey: ToastKey.UploadAttachAction,
      actionKey,
      error: e,
      currentAction: uploadAttachAction(file)
    }));
  }
});
export const sendFeedbackAction = (formData: IPostFormModel, cb: () => void): ThunkAction<void, IStore, unknown, Action> => (async (dispatch) => {
  const actionKey = sendFeedback;

  try {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    await postFeedbackApi(formData);
    dispatch(clearAttachAction());
    dispatch(finishLoadingAction(actionKey));
    cb();
  } catch (e) {
    console.log('sendFeedbackAction', e);
    dispatch(errorHandler({
      toastKey: ToastKey.SendFeedbackAction,
      actionKey,
      error: e,
      currentAction: sendFeedbackAction(formData, cb)
    }));
  }
});
export const joinNewsletterAction = (formData: INewsletterFormModel, cb: () => void): ThunkAction<void, IStore, unknown, Action> => (async (dispatch) => {
  const actionKey = sendFeedback;

  try {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    await postMessageApi(formData);
    dispatch(clearAttachAction());
    dispatch(finishLoadingAction(actionKey));
    cb();
  } catch (e) {
    console.log('joinNewsletterAction', e);
    dispatch(errorHandler({
      toastKey: ToastKey.SendFeedbackAction,
      actionKey,
      error: e,
      currentAction: joinNewsletterAction(formData, cb)
    }));
  }
});
