import {IMediaActionHandler, IMediaInitialState} from './interfaces';
import {LOADED_MEDIA, MediaActionsType} from './actions';

const ContentInitialState: IMediaInitialState = {};

const setMediaKey = ({
  state,
  payload
}: IMediaActionHandler): IMediaInitialState => {
  return {
    ...state,
    [payload.key]: payload.mediaUrl
  };
};

const statusReducerHandlers = new Map([
  [LOADED_MEDIA, setMediaKey]
]);

const mediaReducer = (state = ContentInitialState, action: MediaActionsType): IMediaInitialState => {
  const handler = statusReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IMediaActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default mediaReducer;
