import {IAction} from 'redux/interface';
import {IMediaPayload} from './interfaces';
import {InferActionsTypes} from '../helpers';

export const LOADED_MEDIA = 'LOADED_MEDIA';
export const mediaLoadedAction = (media: IMediaPayload): IAction<IMediaPayload> => ({
  type: LOADED_MEDIA,
  payload: media
});

const mediaActions = {
  mediaLoadedAction
};

export type MediaActionsType = InferActionsTypes<typeof mediaActions>
