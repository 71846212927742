import React, {ChangeEvent} from 'react';
import cn from 'classnames';

import './styles.scss';

interface IGritxButtonProps {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  disabled?: boolean;
  options: { id: string | number; name: string }[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const GritxSelect = ({
  name,
  className,
  placeholder,
  defaultValue,
  disabled = false,
  options,
  onChange,
  onBlur
}: IGritxButtonProps): JSX.Element => {
  return <div className={cn('gritx-select', className)}>
    <select
      name={name}
      className='gritx-select__form-component'
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    >
      {placeholder && <option value={'placeholder'} disabled hidden>{placeholder}</option>}
      {
        options.map(option => {
          return <option key={option.id} value={option.id}>
            {option.name}
          </option>;
        })
      }
    </select>
  </div>;
};
