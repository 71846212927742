import './styles.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {useGetTranslations} from '../../utils/hooks/useGetTranslations';
import {TranslationKeys} from '../../utils/constants/routeConstants';
import {
  articleAsyncActions, getTeiArticle, getTeiArticleAuthors, getTeiArticleReference, getTeiReferenceAuthors
} from '../../redux/pageAsyncActions';
import './styles.scss';
import {closeArticleAuthors, closeArticleReference} from '../../redux/article/actions';
import Loader from '../../components/loader';
import {FormattedMessage} from 'react-intl';

export const ArticleAuthors: React.FunctionComponent = () => {
  const {
    article: {
      article,
      reference,
      authors
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {articleId, referenceId} = useParams();

  useGetTranslations({
    translationKey: TranslationKeys.Article
  });

  useEffect(() => {
    dispatch(closeArticleAuthors());
    if (referenceId && articleId) {
      if (!reference || (reference.id.toString()) !== referenceId) {
        dispatch(articleAsyncActions[getTeiArticleReference](articleId, referenceId));
      }
      dispatch(articleAsyncActions[getTeiReferenceAuthors](articleId, referenceId));
    } else if (articleId) {
      dispatch(closeArticleReference());
      if (!article || article.id.toString() !== articleId) {
        dispatch(articleAsyncActions[getTeiArticle](articleId));
      }
      dispatch(articleAsyncActions[getTeiArticleAuthors](articleId));
    }
  }, [articleId, referenceId]);

  return <div className="article-authors-page">
    <div className="article-authors-page-breadcrumbs">
      {article && <div className="article-authors-page-breadcrumbs-item">
        <Link to={`/article/${article.id}`}><FormattedMessage
          id={'gritx.article.article'}
          defaultMessage={'Article'}
        />
        </Link></div>}
      {reference && !article && <div className="article-authors-page-breadcrumbs-item">
        <Link to={`/article/${reference.articleId}`}><FormattedMessage
          id={'gritx.article.article'}
          defaultMessage={'Article'}
        />
        </Link></div>}
      {reference && <div className="article-authors-page-breadcrumbs-item">
        <Link to={`/article/references/${reference.articleId}`}><FormattedMessage
          id={'gritx.article.references'}
          defaultMessage={'References'}
        /></Link>
      </div>}
      {reference && <div className="article-authors-page-breadcrumbs-item">
        <Link to={`/article/reference/${reference.articleId}/${reference.id}`}>
          <FormattedMessage
            id={'gritx.article.reference'}
            defaultMessage={'Reference'}
          />
        </Link>
      </div>}
    </div>
    <div className="article-authors-page-header">
      <div className="article-authors-page-header__title">
        <FormattedMessage
          id={'gritx.article.authors'}
          defaultMessage={'Authors'}
        />
      </div>
    </div>
    <div>
      {(loading.includes(getTeiArticleAuthors) || loading.includes(getTeiReferenceAuthors)) && <Loader className="article-authors-page-loader"/>}
      <div className="article-authors-body">
        {authors.map((a, i) => {
          return <div className="article-authors-body-author" key={i}>
            <div>{a.fullName}, {a.email}, {a.affiliation}</div>
          </div>;
        })}
      </div>
    </div>
  </div>;
};
