import {FormattedMessage, useIntl} from 'react-intl';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import Debounce from 'lodash/debounce';
import cn from 'classnames';

import {contentAsyncActions, getArticle, getTranslations} from 'redux/pageAsyncActions';
import {IArticleCard} from 'redux/content/interfaces';
import {IStore} from 'redux/interface';

import Article from 'components/article';
import ArticleCard from 'components/article-card';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import NotFoundInfo from 'components/not-found-info';
import Loader from 'components/loader';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {proUser} from 'utils/constants/constants';
import {TranslationKeys} from 'utils/constants/routeConstants';

import CrossroadImg from 'assets/image/library/skill-crossroad.svg';

import './styles.scss';

enum LayoutType {
  article = 'article',
  Library = 'library',
  Backpack = 'backpack',
  Materials = 'materials'
}

const layouts = [
  LayoutType.Library,
  LayoutType.Backpack,
  LayoutType.Materials
];

export const Content: React.FunctionComponent = () => {
  const {
    content: {
      article,
      content,
      backpackContent,
      materialsContent
    },
    status: {
      loading,
      errors
    },
    auth: {
      auth0User,
      userProfile
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useLocation();
  const history = useNavigate();
  const [layoutType, setLayoutType] = useState(LayoutType.article);
  const [articleId, setArticleId] = useState<string | null>(null);
  const [cardList, setCardList] = useState<IArticleCard[]>([]);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const proFlag = auth0User?.['https://gritx.org/roles'].includes(proUser);

  useGetTranslations({
    translationKey: TranslationKeys.Library
  });

  useGetTranslations({
    translationKey: TranslationKeys.Content
  });

  function handleScroll() {
    return Debounce((e) => {
      setCurrentScrollPosition(e.target.scrollingElement.scrollTop);
    }, 100);
  }

  useEffect(() => {
    setLayoutType(layouts.find((layout) => location.state?.parentLocation.includes(layout)) || LayoutType.article);
    document.addEventListener('scroll', handleScroll());

    return () => document.removeEventListener('scroll', handleScroll());
  }, []);

  useEffect(() => {
    if (userProfile && location.hash) {
      const id = location.hash.substr(1);

      window.scrollTo(0, 0);
      setArticleId(id);
      dispatch(contentAsyncActions[getArticle](id));
    }
  }, [location, userProfile]);

  useEffect(() => {
    if (layoutType === LayoutType.Library) {
      setCardList(content);
    }
    if (layoutType === LayoutType.Backpack) {
      setCardList(backpackContent);
    }
    if (layoutType === LayoutType.Materials) {
      setCardList(materialsContent);
    }
  }, [layoutType, content, backpackContent, materialsContent]);

  useEffect(() => {
    if (currentScrollPosition > 500 && currentScrollPosition <= prevScrollPosition) {
      setShowBackToTopButton(true);
    } else {
      setShowBackToTopButton(false);
    }
    setPrevScrollPosition(currentScrollPosition);
  }, [currentScrollPosition]);

  function handleBackClick() {
    history(`/${layoutType}`);
  }

  function handleScrollToTop() {
    window.scrollTo(0, 0);
  }

  function handleOpenCard(cardId: number) {
    return () => {
      history(`/content#${cardId}`);
      // history.push(
      //   `/content#${cardId}`,
      //   history.location.state ? history.location.state : {parentLocation: history.location.pathname}
      // );
    };
  }

  function renderBreadcrumbs() {
    if (layoutType !== LayoutType.article) {
      return <div className="breadcrumbs">
        <Link className="breadcrumbs__previous" to={`/${layoutType}`}>
          <FormattedMessage
            id={`gritx.content-page.${layoutType}.breadcrumb.previous`}
            defaultMessage={layoutType}
          />
        </Link> - <span className="breadcrumbs__current">
          <FormattedMessage
            id={`gritx.content-page.${layoutType}.breadcrumb.current`}
            defaultMessage={'Results'}
          />
        </span>
      </div>;
    }

    return null;
  }

  function renderBackToTop() {
    return showBackToTopButton
      ? <div className="content__back-to-top">
        <GritxButton
          title={intl.formatMessage({
            id: 'gritx.content-page.back-to-top',
            defaultMessage: 'Back to top'
          })}
          variant={ButtonVariant.Light}
          onClick={handleScrollToTop}
        />
      </div>
      : null;
  }

  function renderArticle() {
    if (loading.includes(getArticle)) {
      return <div className="content__view">
        <div className="content__data"><Loader nested/></div>
      </div>;
    }

    if (errors[getArticle]?.error.message.includes('500') || errors[getArticle]?.error.message.includes('400')) {
      return <div className="content__view">
        <div className="content__data">
          <NotFoundInfo
            title={intl.formatMessage({
              id: 'gritx.content-page.notFoundCard',
              defaultMessage: 'Sorry, we can\'t find the card you are looking for.'
            })}
            image={CrossroadImg}/>
        </div>
      </div>;
    }

    if (article) {
      return <div className="content__view">
        <div className="content__data">
          <Article card={article}/>
          <div className="content__footer">
            {
              layoutType !== LayoutType.article
              && <GritxButton
                title={intl.formatMessage({
                  id: 'gritx.common.button.back',
                  defaultMessage: 'Back'
                })}
                variant={ButtonVariant.Outline}
                onClick={handleBackClick}
              />
            }
          </div>
        </div>
      </div>;
    }

    return null;
  }

  return <div className={cn('content', {
    'content--no-article': article === null && !loading.includes(getArticle),
    'content--no-items': cardList.length === 0
  })}>
    {
      !loading.includes(getTranslations) && <>
        {renderBreadcrumbs()}
        {renderBackToTop()}
        <div className="content__wrapper">
          {
            layoutType !== LayoutType.article
            && cardList.length > 0
            && <div className="content__articles">
              {
                <div className="content__list">
                  {
                    cardList.map(card => {
                      return <ArticleCard
                        key={card.id}
                        card={card}
                        active={card.id.toString() === articleId}
                        proUser={proFlag}
                        onClick={handleOpenCard(card.id)}
                      />;
                    })
                  }
                </div>
              }
            </div>
          }

          {renderArticle()}
        </div>
      </>
    }
  </div>;
};
