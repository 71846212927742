import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {IStore} from 'redux/interface';
import {errorHandler} from 'redux/errorHandler';
import {
  BotType,
  HistoryRequestType,
  IBot,
  IBotAnswer,
  IDialog,
  IMessageModel,
  IUseChatBot,
  IXpedition
} from './interfaces';
import {ToastKey} from 'components/gritx-toast/ToastKeyEnum';
import {MessageType} from '@wholesalechange/chatcomponent';

function getTimeZoneOffset() {
  const tz = (new Date().getTimezoneOffset() * (-1)) / 60;

  return tz > 0 ? `+${tz.toString()}` : tz.toString();
}

function getHeaders(token: string): { Authorization: string, API_KEY: string | undefined } {
  return {
    Authorization: token,
    API_KEY: process.env.REACT_APP_API_KEY
  };
}

export const useChatBot = (botType: BotType): IUseChatBot => {
  const {
    auth: {
      auth0Client,
      auth0User
    },
    translation: {
      locale
    }
  } = useSelector((store: IStore) => store);
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<IDialog | null>(null);
  const [botAnswer, setBotAnswers] = useState<IBotAnswer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [xpeditionList, setXpeditionList] = useState<IXpedition[]>([]);
  const [dialogueMessages, setDialogueMessages] = useState<IBotAnswer[]>([]);

  async function getToken(): Promise<string> {
    try {
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        return await auth0Client.getTokenSilently();
      }
    } catch (e) {
      console.log('useChatBot getToken', e);
      dispatch(errorHandler({
        toastKey: ToastKey.GetToken,
        actionKey: 'useChatBot getToken',
        error: e
      }));
    }

    return '';
  }

  async function makeDialogRead(dialogId: number): Promise<void> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.post(
      `${process.env.REACT_APP_API_URL}/dialog/read`,
      {
        dialogueId: dialogId
      },
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response;
  }

  async function fetchMedia(answers: IBotAnswer[] = []) {
    answers.forEach(answer => {
      if (answer.type !== MessageType.Picture
        && !answer.picture) {
        return;
      }

      if (answer.picture?.fileId && !answer.picture.fileUrl) {
        axios
          .get<{ value: string }>(`${process.env.REACT_APP_API_URL}/media/download/${answer.picture.fileId}/false`)
          .then(urlData => {
            if (answer.picture && urlData.data && urlData.data.value) {
              const newAnswers = answers;
              const newAnswer = answer;
              const index = newAnswers.findIndex(an => an.messageId === answer.messageId);

              newAnswer.picture = {
                fileId: answer.picture.fileId,
                fileUrl: urlData.data.value
              };
              newAnswers[index] = newAnswer;
              setBotAnswers([...newAnswers]);
            }
          })
          .catch((error) => {
            console.log('useChatBot fetchMedia', error);
            dispatch(errorHandler({
              toastKey: ToastKey.FetchMedia,
              actionKey: 'useChatBot fetchMedia',
              error: error
            }));
          });
      }
    });
  }

  async function sendMessage<T extends MessageType>(type: T, data: IMessageModel[T]) {
    setBotAnswers([]);
    const token = await getToken();

    if (dialog && botType !== BotType.Interview) {
      await makeDialogRead(dialog.id);
    }
    setIsLoading(true);
    const {data: answers} = await axios.post<IBotAnswer[]>(
      `${process.env.REACT_APP_API_URL}/message/send`,
      {
        dialogueId: dialog?.id,
        date: new Date().toISOString(),
        message: {
          ...data,
          sender: {
            type: 'CLIENT',
            name: auth0User?.nickname,
            avatar: 0, // todo get user avatar
            zoneOffset: getTimeZoneOffset()
          },
          type
        }
      },
      {headers: getHeaders(token)}
    );

    setBotAnswers(answers);
    if (answers.length) {
      await fetchMedia(answers);
    }
    setIsLoading(false);
  }

  async function addDialog(botId: number): Promise<void> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.post(
      `${process.env.REACT_APP_API_URL}/dialog/add`,
      {botId},
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response;
  }

  async function getBotList(): Promise<IBot[]> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.get(
      `${process.env.REACT_APP_API_URL}/bot/list`,
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response || [];
  }

  async function getDialogList(): Promise<IDialog[]> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.get(
      `${process.env.REACT_APP_API_URL}/dialog/list`,
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response || [];
  }

  async function getMainDialog(): Promise<IDialog | null> {
    const token = await getToken();

    try {
      const {data} = await axios.get(
        `${process.env.REACT_APP_API_URL}/dialog/main`,
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return data;
    } catch (e) {
      if (e?.response?.status === 400) {
        try {
          const botList = await getBotList();
          const mainBot = botList.find((bot) => bot.indMain);
          const dialogList = await getDialogList();
          // look for main dialog
          const mainDialog = dialogList.filter((dialogItem) => dialogItem.botId === mainBot?.id);

          // if maindialog found

          if (mainDialog.length) {
            setIsLoading(false);

            return mainDialog[0];
          }

          // if main dialog not found

          if (mainBot) {
            await addDialog(mainBot.id);

            return await getMainDialog();
          }
        } catch (error) {
          console.log('useChatBot getBotList', error);
          dispatch(errorHandler({
            toastKey: ToastKey.GetBotList,
            actionKey: 'useChatBot getBotList',
            error: error
          }));
        }
      }
      console.log('useChatBot getMainDialog', e);
      dispatch(errorHandler({
        toastKey: ToastKey.GetMainDialog,
        actionKey: 'useChatBot getMainDialog',
        error: e
      }));

      return null;
    }
  }

  async function getDialogById(dialogId: number): Promise<IDialog | null> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.get<IDialog>(
      `${process.env.REACT_APP_API_URL}/dialog/${dialogId}`,
      {headers: getHeaders(token)}
    );

    setIsLoading(false);
    if (botType === BotType.Xpedition || botType === BotType.Dialogue) {
      setDialog(response);
    }

    return response || null;
  }

  async function setDialogLanguage(language: string, dialogId = dialog?.id) {
    if (!dialogId) {
      return null;
    }
    setIsLoading(true);
    const token = await getToken();

    try {
      const {data: response} = await axios.post<{ value: boolean }>(
        `${process.env.REACT_APP_API_URL}/dialog/set/language`,
        {
          id: dialogId,
          language
        },
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return response;
    } catch (error) {
      console.log('setDialogLanguage', error);
      dispatch(errorHandler({
        toastKey: ToastKey.SetDialogLanguage,
        actionKey: 'useChatBot setDialogLanguage',
        error: error
      }));

      return null;
    }
  }

  async function removeDialog(dialogId: number): Promise<void> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.post(
      `${process.env.REACT_APP_API_URL}/dialog/remove`,
      {
        dialogueId: dialogId
      },
      {headers: getHeaders(token)}
    );

    setIsLoading(false);
    if (dialogId === dialog?.id) {
      setDialog(null);
    }
    setBotAnswers([]);

    return response;
  }

  async function setDialogAvatar(dialogId: number, fileId: number): Promise<void> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.post(
      `${process.env.REACT_APP_API_URL}/dialog/set/avatar`,
      {
        dialogueId: dialogId,
        fileId
      },
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response;
  }

  async function setDialogName(dialogId: number, dialogName: string): Promise<void> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.post(
      `${process.env.REACT_APP_API_URL}/dialog/set/avatar`,
      {
        dialogueId: dialogId,
        dialogueName: dialogName
      },
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response;
  }

  async function getDialogHistory(
    dialogId: number,
    type: HistoryRequestType,
    limit = 10,
    offset = 0
  ): Promise<IBotAnswer[] | null> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.get<IBotAnswer[]>(
      `${process.env.REACT_APP_API_URL}/history/${dialogId}?type=${type}&limit=${limit}&offset=${offset}`,
      {headers: getHeaders(token)}
    );

    setIsLoading(false);
    fetchMedia(response);
    if (botType === BotType.Xpedition || botType === BotType.Interview) {
      setBotAnswers(response.reverse());
    }

    return response || null;
  }

  // ------xpedition------
  async function getXpeditionList() {
    setXpeditionList([]);
    const token = await getToken();

    try {
      const {data: xpeditions} = await axios.get<IXpedition[]>(
        `${process.env.REACT_APP_API_URL}/xpedition/list`,
        {
          headers: getHeaders(token),
          params: {
            language: locale
          }
        }
      );

      setXpeditionList(xpeditions);
    } catch (error) {
      console.log('getXpeditionList', error);
      dispatch(errorHandler({
        toastKey: ToastKey.GetXpeditionList,
        actionKey: 'useChatBot getXpeditionList',
        error: error
      }));
    }
  }

  async function startXpedition(xpeditionId: number) {
    const token = await getToken();

    try {
      const {data: {value: dialogId} } = await axios.post<{ value: number }>(
        `${process.env.REACT_APP_API_URL}/xpedition/start`,
        {
          xpeditionId,
          language: locale
        },
        {headers: getHeaders(token)}
      );

      await getDialogById(dialogId);
      await getDialogHistory(dialogId, HistoryRequestType.Last);
    } catch (error) {
      console.log('startXpedition', error);
      dispatch(errorHandler({
        toastKey: ToastKey.StartXpedition,
        actionKey: 'useChatBot startXpedition',
        error: error
      }));
    }
  }

  async function resumeDialogue(dialogueId: number) {
    try {
      await getDialogById(dialogueId);
      await getDialogHistory(dialogueId, HistoryRequestType.All);
    } catch (error) {
      console.log('resumeDialogue', error);
      dispatch(errorHandler({
        toastKey: ToastKey.ResumeDialogue,
        actionKey: 'useChatBot ResumeDialogue',
        error: error
      }));
    }
  }

  // ---------------------

  // --------self-interview-------
  async function startInterview(interviewId: number) {
    const token = await getToken();

    setIsLoading(true);
    try {
      const {data: {value: dialogId} } = await axios.post<{ value: number }>(
        `${process.env.REACT_APP_API_URL}/self-interview/start`,
        {
          interviewId,
          alwaysCreate: true,
          language: locale
        },
        {headers: getHeaders(token)}
      );

      const interviewDialog = await getDialogById(dialogId);

      await getDialogHistory(dialogId, HistoryRequestType.Last);
      setIsLoading(false);
      setDialog(interviewDialog);
    } catch (error) {
      console.log('startInterview', error);
      dispatch(errorHandler({
        toastKey: ToastKey.StartInterview,
        actionKey: 'useChatBot startInterview',
        error: error
      }));
    }
  }

  // -----------------------------

  useEffect(() => {
    if (botType === BotType.Chat) {
      getMainDialog()
        .then((data) => {
          setDialog(data);
        });
    }
  }, []);

  return {
    currentDialog: dialog,
    isLoading,
    botAnswer,
    sendMessage,
    getBotList,
    xpedition: {
      getXpeditionList,
      xpeditionList,
      startXpedition
    },
    dialogMethods: {
      addDialog,
      getDialogList,
      getMainDialog,
      getDialogById,
      makeDialogRead,
      removeDialog,
      setDialogAvatar,
      setDialogName,
      startInterview,
      setDialogLanguage,
      resumeDialogue
    }
  };
};
