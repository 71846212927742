import {
  loadArticleAction,
  loadBackpackContentAction,
  loadLibContentAction,
  loadLibFiltersAction,
  loadMaterialsContentAction,
  loadMaterialsTemplatesAction,
  loadPublicArticleAction, loadResourceContentAction,
  loadResourceFiltersAction,
  saveJournalAction,
  sendMaterialsAction
} from './content/contentAsyncActions';
import {
  addBotToDialogueAction,
  addUsersToDialogueAction, blockParticipantAction,
  createDialogueAction,
  loadBotsAction,
  loadDialogueAction, loadDialogueParticipantAction,
  loadDialogueParticipantsAction,
  loadDialoguesAction, loadXpeditionsAction,
  removeDialogueAction,
  removeParticipantFromDialogueAction, restartXpeditionAction,
  setDialogueNameAction, startXpeditionAction, unlockParticipantAction
} from './dialogue/dialogueAsyncActions';
import {
  loadTeiArticleAction,
  loadTeiArticleAuthorsAction,
  loadTeiArticleReferenceAction,
  loadTeiArticleReferencesAction,
  loadTeiArticleSectionAction,
  loadTeiArticleSectionsAction,
  loadTeiReferenceAuthorsAction
} from './article/articleAsyncActions';

export const getTranslations = 'GET_TRANSLATIONS';
export const getLibFilters = 'GET_LIBRARY_FILTERS';
export const getResourceFilters = 'GET_RESOURCE_FILTERS';
export const getLibContent = 'GET_LIBRARY_CONTENT';
export const getResourceContent = 'GET_RESOURCE_CONTENT';
export const getBackpackContent = 'GET_BACKPACK_CONTENT';
export const getMaterialsContent = 'GET_MATERIALS_CONTENT';
export const getArticle = 'GET_ARTICLE';
export const sendFeedback = 'SEND_FEEDBACK';
export const getTemplates = 'GET_TEMPLATES';
export const sendMaterials = 'SEND_MATERIALS';
export const saveJournal = 'SAVE_JOURNAL';
export const getDialogues = 'GET_DIALOGUES';
export const setDialogueName = 'SET_DIALOGUE_NAME';
export const createDialogue = 'CREATE_DIALOGUE';
export const removeDialogue = 'REMOVE_DIALOGUE';
export const removeMainDialogue = 'REMOVE_MAIN_DIALOGUE';
export const getDialogue = 'GET_DIALOGUE';
export const getDialogueParticipants = 'GET_DIALOGUE_PARTICIPANTS';
export const getDialogueParticipant = 'GET_DIALOGUE_PARTICIPANT';
export const getBots = 'GET_BOTS';
export const addBotToDialogue = 'ADD_BOT_TO_DIALOGUE';
export const addUsersToDialogue = 'ADD_USERS_TO_DIALOGUE';
export const removeParticipantFromDialogue = 'REMOVE_PARTICIPANT_FROM_DIALOGUE';
export const blockParticipant = 'BLOCK_PARTICIPANT';
export const unlockParticipant = 'UNLOCK_PARTICIPANT';
export const startXpedition = 'START_XPEDITION';
export const restartXpedition = 'RESTART_XPEDITION';
export const getXpeditions = 'GET_XPEDITIONS';
export const createVideoCall = 'CREATE_VIDEO_CALL';
export const getVideoCallToken = 'GET_VIDEO_CALL_TOKEN';
export const getVideoCallRtmToken = 'GET_VIDEO_CALL_RTM_TOKEN';
export const finishVideoCall = 'FINISH_VIDEO_CALL';
export const getVideoCallActive = 'GET_VIDEO_CALL_ACTIVE';
export const setDialogueLanguage = 'SET_DIALOGUE_LANGUAGE';
export const getPublicArticle = 'GET_PUBLIC_ARTICLE';
export const getTeiArticle = 'GET_TEI_ARTICLE';
export const getTeiArticleAuthors = 'GET_TEI_ARTICLE_AUTHORS';
export const getTeiArticleReferences = 'GET_TEI_ARTICLE_REFERENCES';
export const getTeiArticleReference = 'GET_TEI_ARTICLE_REFERENCE';
export const getTeiArticleSection = 'GET_TEI_ARTICLE_SECTION';
export const getTeiReferenceAuthors = 'GET_TEI_REFERENCE_AUTHORS';
export const getTeiArticleSections = 'GET_TEI_SECTIONS';

export const libraryAsyncActions = {
  [getLibFilters]: loadLibFiltersAction,
  [getLibContent]: loadLibContentAction
};

export const backpackAsyncActions = {
  [getBackpackContent]: loadBackpackContentAction
};

export const materialsAsyncActions = {
  [getMaterialsContent]: loadMaterialsContentAction,
  [getTemplates]: loadMaterialsTemplatesAction,
  [sendMaterials]: sendMaterialsAction
};

export const contentAsyncActions = {
  [getArticle]: loadArticleAction,
  [saveJournal]: saveJournalAction,
  [getPublicArticle]: loadPublicArticleAction
};

export const chatAsyncActions = {
  [getArticle]: loadArticleAction
};

export const dialogueAsyncActions = {
  [getDialogues]: loadDialoguesAction,
  [setDialogueName]: setDialogueNameAction,
  [createDialogue]: createDialogueAction,
  [removeDialogue]: removeDialogueAction,
  [getDialogue]: loadDialogueAction,
  [getDialogueParticipants]: loadDialogueParticipantsAction,
  [getDialogueParticipant]: loadDialogueParticipantAction,
  [getBots]: loadBotsAction,
  [addBotToDialogue]: addBotToDialogueAction,
  [addUsersToDialogue]: addUsersToDialogueAction,
  [removeParticipantFromDialogue]: removeParticipantFromDialogueAction,
  [blockParticipant]: blockParticipantAction,
  [unlockParticipant]: unlockParticipantAction,
  [startXpedition]: startXpeditionAction,
  [restartXpedition]: restartXpeditionAction,
  [getXpeditions]: loadXpeditionsAction
};

export const articleAsyncActions = {
  [getTeiArticle]: loadTeiArticleAction,
  [getTeiArticleAuthors]: loadTeiArticleAuthorsAction,
  [getTeiArticleReferences]: loadTeiArticleReferencesAction,
  [getTeiArticleReference]: loadTeiArticleReferenceAction,
  [getTeiReferenceAuthors]: loadTeiReferenceAuthorsAction,
  [getTeiArticleSection]: loadTeiArticleSectionAction,
  [getTeiArticleSections]: loadTeiArticleSectionsAction
};

export const resourceAsyncActions = {
  [getResourceFilters]: loadResourceFiltersAction,
  [getResourceContent]: loadResourceContentAction
};
