import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import * as EmailValidator from 'email-validator';
import {FormattedMessage, useIntl} from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

import {IStore} from 'redux/interface';
import {maxEmails} from 'utils/constants/constants';

import {ReactComponent as PlusIcon} from 'assets/image/materials/plus.svg';
import {ReactComponent as QuestionIcon} from 'assets/image/materials/question.svg';

import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import EmailBox from './email-box';
import './styles.scss';

type Props = {
  initialEmails: string;
  initialToSelf: boolean;
  onChange: (parsedEmails: string[], toSelf: boolean) => void;
}

export const EmailZone = ({initialEmails, initialToSelf, onChange}: Props): JSX.Element => {
  const user = useSelector((state: IStore) => state.auth.auth0User);
  const intl = useIntl();
  const [toSelf, setToSelf] = useState<boolean>(initialToSelf);
  const [inputEmails, setInputEmails] = useState<string>('');
  const [parsedEmails, setParsedEmails] = useState<string[]>(initialEmails.length > 1 ? initialEmails.split(',') : []);
  const [error, setError] = useState(false);

  useEffect(() => {
    setToSelf(initialToSelf);
  }, [initialToSelf]);

  useEffect(() => {
    setParsedEmails(initialEmails.length > 1 ? initialEmails.split(',') : []);
  }, [initialEmails]);

  useEffect(() => {
    onChange(parsedEmails, toSelf);
  }, [parsedEmails, toSelf]);

  function showError() {
    setError(true);
    setTimeout(setError, 5000, false);
  }

  function parseEmails() {
    const emails = inputEmails.split(/(?:,| |;|\n)+/);

    setInputEmails('');
    for (let i = 0; i < emails.length; i++) {
      if (EmailValidator.validate(emails[i]) && !parsedEmails.includes(emails[i])) {
        if (parsedEmails.length === maxEmails) {
          showError();
          break;
        }
        parsedEmails.push(emails[i]);
        const newEmails = [...parsedEmails];

        setParsedEmails(newEmails);
      }
    }
  }

  const keydownHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
      event.preventDefault();
      parseEmails();
    }
  };

  function deleteEmail(email: string) {
    setParsedEmails(parsedEmails.filter(item=> item !== email));
  }

  function handleDeleteEmail(email: string) {
    return () => { deleteEmail(email); };
  }

  function sendToSelf() {
    setToSelf(!toSelf);
  }

  function removeAll() {
    setParsedEmails([]);
  }

  return <div className="email-area">
    <div className="email-zone">
      <div className="email-zone__title">
        <FormattedMessage
          id={'gritx.materials.emailZone.title'}
          defaultMessage={'Add emails separated by , ; or space'}
        />
      </div>
      <div className="email-zone__data">
        <div className="email-zone__form">
          <b>
            <FormattedMessage
              id={'gritx.materials.emailZone.to'}
              defaultMessage={'To:'}
            />
          </b>
          <TextareaAutosize
            className="email-zone__input"
            maxRows={3}
            value={inputEmails}
            onKeyDown={keydownHandler}
            onChange={ev => {
              setInputEmails(ev.target.value);
            }}
          />
          <button className="email-zone__add-btn" onClick={parseEmails} disabled={inputEmails.length < 5}>
            <PlusIcon className="email-zone__image"/>
          </button>
        </div>
        <div className="email-zone__output">
          {
            parsedEmails.map((email, i) => {
              return <EmailBox key={i} email={email} deleteEmail={handleDeleteEmail(email)}/>;
            })
          }
        </div>
        <div className="email-zone__footer">
          <div className="email-zone__cc-to-self">
            <label className="email-zone__check">
              <input className="email-zone__check-input" type="checkbox" checked={toSelf} onChange={sendToSelf} disabled={!user?.email}/>
              <span className="email-zone__check-box"></span>
              <FormattedMessage
                id={'gritx.materials.emailZone.self'}
                defaultMessage={'CC to self'}
              />
            </label>
            {!user?.email && <QuestionIcon
              className="email-zone__to-self-info"
              title={intl.formatMessage({
                id: 'gritx.materials.emailZone.addEmail',
                defaultMessage: 'Please, add an e-mail in your Account Settings'
              })}/>}
          </div>
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.materials.emailZone.removeAll',
              defaultMessage: 'Remove all'
            })}
            className="email-zone__remove-all-btn"
            variant={ButtonVariant.Outline}
            onClick={removeAll}
            disabled={parsedEmails.length === 0}
          />
        </div>
      </div>
    </div>
    {error && <h2 className="email-zone__error">
      <FormattedMessage
        id={'gritx.materials.emailZone.error'}
        defaultMessage={'You can add up to 40 e-mails.'}
      />
    </h2>}
  </div>;
};
