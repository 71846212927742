import {emailRegExp} from '../constants/constants';

export function isRequiredValidator(value: string): boolean {
  return Boolean(value.trim().length);
}

export function minLengthValidator(value: string, length: number): boolean {
  return value.length < length;
}

export function maxLengthValidator(value: string, length: number): boolean {
  return value.length >= length;
}

export function isValidEmail(value: string): boolean {
  return emailRegExp.test(value);
}
