import {
  HistoryRequestType,
  IBot,
  IDialog,
  IMessageModel
} from './use-chat-bot/interfaces';
import {MessageType, BotMode, BotType, IBotAnswer} from '@wholesalechange/chatcomponent';
import {IParticipant} from '../../redux/dialogue/interfaces';
import {useEffect, useMemo, useState} from 'react';
import {useWebsocket} from './useWebsocket';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {errorHandler} from '../../redux/errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import axios from 'axios/index';
import {useLoadFile} from './useLoadFile';
import {IMessage} from '@stomp/stompjs';
import {useTimeZoneOffset} from './useTimeZoneOffset';

interface IUseChatBotNew {
  dialogIsNotSet: boolean
  isLoading: boolean
  isSending: boolean
  isTyping: boolean
  isConnected: boolean
  messages: IBotAnswer[] | undefined
  hasMoreMessages: boolean
  botAnswer: IBotAnswer[],
  sendMessage: <T extends MessageType>(type: T, participant: IParticipant, data: IMessageModel[T]) => void

  currentDialog: IDialog | null
  xpedition: {
    setXpeditionDialogue: (dialogueId: number) => void
  },
  dialogueMethods: {
    addDialog: (botId: number) => void
    getDialogById: (dialogId: number) => Promise<IDialog | null>
    getDialogList: () => Promise<IDialog[]>
    getMainDialog: () => Promise<IDialog | null>
    removeDialog: (dialogId: number) => void
    resumeDialogue: (dialogueId: number) => void
    fetchMore: () => void
    fetchDialogueHistory: () => void
    refreshDialogueHistory: () => void
    getBotList: () => Promise<IBot[]>
  }
  showContentInRightPanel: boolean
}

export const useChatBotNew = (botType: BotType, mode: BotMode): IUseChatBotNew => {
  const {
    auth: {
      auth0Client,
      userProfile
    }
  } = useSelector((store: IStore) => store);
  const [dialog, setDialog] = useState<IDialog | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dialogIsNotSet, setDialogIsNotSet] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
  const [messages, setMessages] = useState<IBotAnswer[]>();
  const [newMessages, setNewMessages] = useState<IBotAnswer[] | null>();
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [botAnswer, setBotAnswer] = useState<IBotAnswer[]>([]);
  const timeZoneOffset = useMemo(() => useTimeZoneOffset(), []);
  const [outgoingMessage, setOutgoingMessage] = useState<string | null>(null);

  const dispatch = useDispatch();
  const websocket = useWebsocket();
  const {getFileUrl} = useLoadFile();

  async function getToken(): Promise<string> {
    try {
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        return await auth0Client.getTokenSilently();
      }
    } catch (e) {
      console.log('useChatBotNew getToken', e);
      dispatch(errorHandler({
        toastKey: ToastKey.GetToken,
        actionKey: 'useChatBotNew getToken',
        error: e
      }));
    }

    return '';
  }

  function getHeaders(token: string): { Authorization: string, API_KEY: string | undefined } {
    return {
      Authorization: token,
      API_KEY: process.env.REACT_APP_API_KEY
    };
  }

  async function fetchMedia(answers: IBotAnswer[] = []) {
    answers.forEach(answer => {
      if (answer.sender?.avatar && !answer.sender.avatarUrl) {
        getFileUrl(answer.sender.avatar, (url) => {
          if (url) {
            const newAnswers = answers;
            const newAnswer = answer;
            const index = newAnswers.findIndex(an => an.messageId === answer.messageId);

            newAnswer.sender = {
              ...newAnswer.sender,
              avatarUrl: url
            };
            newAnswers[index] = newAnswer;
          }
        });
      }

      if (answer.type !== MessageType.Picture
        && !answer.picture) {
        return;
      }

      if (answer.picture?.fileId && !answer.picture.fileUrl) {
        getFileUrl(answer.picture.fileId, (url) => {
          if (answer.picture && url) {
            const newAnswers = answers;
            const newAnswer = answer;
            const index = newAnswers.findIndex(an => an.messageId === answer.messageId);

            newAnswer.picture = {
              fileId: answer.picture.fileId,
              fileUrl: url
            };
            newAnswers[index] = newAnswer;
          }
        });
      }
    });
  }

  async function fetchDialogueHistory(
    type = HistoryRequestType.All,
    limit = 10,
    offs = 0
  ): Promise<IBotAnswer[] | null> {
    setIsLoading(true);
    try {
      const token = await getToken();
      const {data: response} = await axios.get<IBotAnswer[]>(
        `${process.env.REACT_APP_API_URL}/history/${dialog?.id}?type=${type}&limit=${limit}&offset=${offs}`,
        {headers: getHeaders(token)}
      );

      await fetchMedia(response);
      setHasMoreMessages(!response.length);
      if (offs) {
        const allMessages = messages ? response.reverse().concat(messages) : response.reverse();

        setMessages(allMessages);
      } else {
        setMessages(response.reverse());
      }
      setOffset(offs + response.length);

      setIsLoading(false);

      return response || null;
    } catch (e) {
      console.log('useChatBotNew fetchDialogueHistory', e);
      dispatch(errorHandler({
        toastKey: ToastKey.GetDialogueHistory,
        actionKey: 'fetchDialogueHistory',
        error: e
      }));
      setIsLoading(false);

      return null;
    }
  }

  async function removeDialog(dialogId: number): Promise<void> {
    setIsLoading(true);

    try {
      const token = await getToken();
      const {data: response} = await axios.post(
        `${process.env.REACT_APP_API_URL}/dialog/remove`,
        {
          dialogueId: dialogId
        },
        {headers: getHeaders(token)}
      );

      setIsLoading(false);
      if (dialogId === dialog?.id) {
        setDialog(null);
      }
      setBotAnswer([]);
    } catch (e) {
      console.log('useChatBotNew removeDialog', e);
      dispatch(errorHandler({
        toastKey: ToastKey.RemoveDialogueAction,
        actionKey: 'removeDialog',
        error: e
      }));
      setIsLoading(false);
    }
  }

  async function fetchMore(): Promise<IBotAnswer[] | null> {
    return fetchDialogueHistory(HistoryRequestType.All, 10, offset);
  }

  async function refreshDialogueHistory(): Promise<IBotAnswer[] | null> {
    const limit = messages?.length && messages?.length > 10 ? messages?.length : 10;

    return fetchDialogueHistory(HistoryRequestType.All, limit, 0);
  }

  function sendDialogueMessage<T extends MessageType>(type: T, participant: IParticipant, data: IMessageModel[T]) {
    if (!userProfile || !dialog) {
      return;
    }
    const message = {
      dialogueID: dialog.id,
      message: {
        ...data,
        sender: {
          id: participant.userId,
          type: 'CLIENT',
          name: userProfile.email,
          avatar: participant.participantAvatar,
          participantName: participant.participantName,
          zoneOffset: timeZoneOffset
        },
        dialogueId: dialog.id,
        type: type,
        showInHistory: true
      }
    };
    const messageStr = JSON.stringify(message);

    setOutgoingMessage(messageStr);
    if (websocket.isConnected) {
      websocket.sendMessage(messageStr);
    }
  }

  function processMessage(msgBody: string) {
    try {
      return JSON.parse(msgBody);
    } catch (e) {
      return msgBody;
    }
  }

  async function getDialogById(dialogId: number): Promise<IDialog | null> {
    setIsLoading(true);
    try {
      const token = await getToken();
      const {data: response} = await axios.get<IDialog>(
        `${process.env.REACT_APP_API_URL}/dialog/${dialogId}`,
        {headers: getHeaders(token)}
      );

      if (botType === BotType.Xpedition || botType === BotType.Dialogue) {
        setDialog(response);
      }
      setIsLoading(false);

      return response || null;
    } catch (e) {
      console.log('useChatBotNew getDialogById', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadDialogueAction,
        actionKey: 'getDialogById',
        error: e
      }));
      setIsLoading(false);

      return null;
    }
  }

  async function getBotList(): Promise<IBot[]> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.get(
      `${process.env.REACT_APP_API_URL}/bot/list`,
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response || [];
  }

  async function addDialog(botId: number): Promise<void> {
    setIsLoading(true);
    try {
      const token = await getToken();
      const {data: response} = await axios.post(
        `${process.env.REACT_APP_API_URL}/dialog/add`,
        {botId},
        {headers: getHeaders(token)}
      );

      setIsLoading(false);
    } catch (e) {
      console.log('useChatBotNew addDialog', e);
      dispatch(errorHandler({
        toastKey: ToastKey.CreateDialogueAction,
        actionKey: 'addDialog',
        error: e
      }));
      setIsLoading(false);
    }
  }

  async function getDialogList(): Promise<IDialog[]> {
    setIsLoading(true);
    const token = await getToken();
    const {data: response} = await axios.get(
      `${process.env.REACT_APP_API_URL}/dialog/list`,
      {headers: getHeaders(token)}
    );

    setIsLoading(false);

    return response || [];
  }

  async function getMainDialog(): Promise<IDialog | null> {
    setIsLoading(true);
    const token = await getToken();

    try {
      const {data} = await axios.get(
        `${process.env.REACT_APP_API_URL}/dialog/main`,
        {headers: getHeaders(token)}
      );

      setIsLoading(false);
      setDialogIsNotSet(false);

      return data;
    } catch (e) {
      if (e?.response?.status === 400) {
        try {
          const botList = await getBotList();
          const mainBot = botList.find((bot) => bot.indMain);
          const dialogList = await getDialogList();
          // look for main dialog
          const mainDialog = dialogList.filter((dialogItem) => dialogItem.botId === mainBot?.id);

          // if maindialog found

          if (mainDialog.length) {
            return mainDialog[0];
          }

          // if main dialog not found

          if (mainBot) {
            await addDialog(mainBot.id);

            return await getMainDialog();
          }
        } catch (error) {
          console.log('useChatBot getBotList', error);
          dispatch(errorHandler({
            toastKey: ToastKey.GetBotList,
            actionKey: 'useChatBot getBotList',
            error: error
          }));
        }
      } else {
        console.log('useChatBot getMainDialog', e);
        dispatch(errorHandler({
          toastKey: ToastKey.GetMainDialog,
          actionKey: 'useChatBot getMainDialog',
          error: e
        }));
      }
      setIsLoading(false);
      setDialogIsNotSet(true);

      return null;
    }
  }

  // ------xpedition------
  function setXpeditionDialogue(dialogueId: number) {
    getDialogById(dialogueId);
  }

  async function resumeDialogue(dialogueId: number) {
    try {
      await getDialogById(dialogueId);
    } catch (error) {
      console.log('resumeDialogue', error);
      dispatch(errorHandler({
        toastKey: ToastKey.ResumeDialogue,
        actionKey: 'useChatBot ResumeDialogue',
        error: error
      }));
    }
  }

  async function fetchLastDialogueHistory() {
    const answers = await fetchDialogueHistory(HistoryRequestType.Last);

    if (answers) {
      setBotAnswer(answers);
    }
  }

  function processXpeditionAnswer(answers: IBotAnswer[]) {
    if (answers[0].sender.type !== 'BOT') {
      setMessages(messages?.concat(answers));
    } else {
      const lastMessage = messages && messages.length ? messages[messages.length - 1] : undefined;

      if (lastMessage && lastMessage.sender.type !== 'BOT') {
        setMessages(answers);
      } else {
        setMessages(messages?.concat(answers));
      }

      const botAnswers = answers
        .filter(answer => !answer.contentType && answer.picture);

      if (botAnswers && botAnswers.length) {
        setBotAnswer(botAnswers);
      }
    }
  }

  // ---------------------

  useEffect(() => {
    if (botType === BotType.Chat) {
      getMainDialog()
        .then((data) => {
          setDialog(data);
        });
    }
  }, []);

  useEffect(() => {
    if (dialog) {
      if (botType === BotType.Xpedition || mode === BotMode.NoHistory) {
        fetchLastDialogueHistory();
      } else {
        fetchDialogueHistory();
      }
    } else {
      setBotAnswer([]);
      setMessages([]);
      setOffset(0);
    }
  }, [dialog]);

  useEffect(() => {
    if (newMessages && newMessages.length) {
      if (botType === BotType.Xpedition || mode === BotMode.NoHistory) {
        processXpeditionAnswer(newMessages);
      } else {
        setMessages(messages?.concat(newMessages));

        const botAnswers = newMessages
          .filter(answer => !answer.contentType && answer.picture && answer.sender.type === 'BOT');

        if (botAnswers && botAnswers.length) {
          setBotAnswer(botAnswers);
        }
        setNewMessages(null);
        setOffset(offset + newMessages.length);
      }
      setNewMessages(null);
    }
  }, [newMessages]);

  useEffect(() => {
    const fetchData = async (answers: IBotAnswer[]) => {
      await fetchMedia(answers);
    };

    if (dialog) {
      websocket.connect(dialog.id, (msg: IMessage) => {
        setOutgoingMessage(null);
        const allMessages: IBotAnswer[] = processMessage(msg.body);
        const typingMessages = allMessages.filter(m => m.type === MessageType.Typing);
        const mess = allMessages.filter(m => m.type !== MessageType.Ok && m.type !== MessageType.Typing);

        setIsTyping(typingMessages && !!typingMessages.length);
        fetchData(mess).then(() => {
          if (mess && !!mess.length) {
            setNewMessages(mess);
          }
        });
      });
    }
  }, [dialog]);

  useEffect(() => {
    if (websocket.isConnected && outgoingMessage) {
      websocket.sendMessage(outgoingMessage);
    }
  }, [websocket.isConnected]);

  return {
    isLoading: isLoading || websocket.isConnecting,
    isSending: !!outgoingMessage,
    dialogIsNotSet: dialogIsNotSet,
    isTyping,
    isConnected: websocket.isConnected,
    messages,
    hasMoreMessages,
    sendMessage: sendDialogueMessage,
    currentDialog: dialog,
    botAnswer,
    xpedition: {
      setXpeditionDialogue
    },
    dialogueMethods: {
      addDialog,
      getDialogList,
      getMainDialog,
      getDialogById,
      resumeDialogue,
      fetchDialogueHistory,
      refreshDialogueHistory,
      fetchMore,
      removeDialog,
      getBotList
    },
    showContentInRightPanel: !!dialog && dialog.showContentInRightPanel
  };
};
