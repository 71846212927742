import React, {ChangeEvent, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

import {isRequiredValidator, maxLengthValidator, minLengthValidator} from 'utils/helpers/validators';

import './styles.scss';

interface ITextBox {
  label?: string
  required?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
  minLength?: number
  maxLength?: number
}

const textBoxErrorTypes = {
  required: 'required',
  minLength: 'minLength',
  maxLength: 'maxLength'
};

const errorMessage: { [type: string]: { id: string; defaultMessage: string } } = {
  [textBoxErrorTypes.required]: {
    id: 'gritx.common.errors.required',
    defaultMessage: 'This field cannot be blank'
  },
  [textBoxErrorTypes.minLength]: {
    id: 'gritx.common.errors.minLength',
    defaultMessage: 'The field should contain at least {minLength} and at most {maxLength} characters'
  },
  [textBoxErrorTypes.maxLength]: {
    id: 'gritx.common.errors.maxLength',
    defaultMessage: 'You have reached the max character limit for this field'
  }
};

function validateValue({
  value,
  required,
  minLength,
  maxLength
}: {
  value: string,
  minLength: number,
  maxLength: number,
  required: boolean
}): string[] {
  let errors = [] as string[];

  if (minLengthValidator(value, minLength)) {
    errors = [textBoxErrorTypes.minLength];
  }
  if (maxLengthValidator(value, maxLength)) {
    errors = [textBoxErrorTypes.maxLength];
  }
  if (required && !isRequiredValidator(value)) {
    errors = [textBoxErrorTypes.required];
  }

  return errors;
}

export const TextBox: React.FunctionComponent<ITextBox> = ({
  label,
  required = false,
  onChange,
  onBlur,
  minLength = 3,
  maxLength = 1000,
  ...props
}: ITextBox) => {
  const [errors, setErrors] = useState<string[]>([]);

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setErrors([]);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  function handleBlur(e: ChangeEvent<HTMLTextAreaElement>) {
    setErrors(validateValue({
      value: e.target.value,
      required,
      maxLength,
      minLength
    }));
    if (onBlur) {
      onBlur();
    }
  }

  return <div className="text-box">
    <label className={'text-box__label'}>
      <span className={'text-box__label-text'}>
        {label}
        {required && <span className={'text-box__required'}>*</span>}
      </span>
      <textarea
        className={cn(
          'text-box__field',
          {'text-box__field--error': Boolean(errors.length)}
        )}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
        minLength={minLength}
        maxLength={maxLength}
        rows={3}
        {...props}
      />
    </label>
    {
      errors.map((e, i) => <span key={i} className="text-box__error">
        {errorMessage[e] && <FormattedMessage
          {...errorMessage[e]}
          values={{
            minLength,
            maxLength
          }}
        />}
      </span>)
    }
  </div>;
};
