import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import cn from 'classnames';

import {tabletsWidth} from 'utils/constants/constants';
import {useResize} from 'utils/hooks/useResize';

import team from './team.json';
import './styles.scss';

interface IRIntl {
  id: string
  defaultMessage: string
}

interface ITeammate {
  name: IRIntl
  description: IRIntl
  position: IRIntl
  imageName: string
}

export const MeetYourTeam: React.FunctionComponent = () => {
  const intl = useIntl();
  const teammates = team as [ITeammate];
  const listRef = useRef<HTMLUListElement>(null);
  const [openDescription, setOpenDescription] = useState<number | null>(null);
  const [descriptionPosition, setDescriptionPosition] = useState<string | null>(null);

  const screenWidth = useResize();

  useEffect(() => {
    if (openDescription !== null) {
      setOpenDescription(null);
    }
  }, [screenWidth]);

  const getTeammateImage = (teammate: ITeammate): JSX.Element => {
    return <img
      className="team__image"
      src={`/assets/team/${teammate.imageName}`}
      alt=""
      role="presentation"
    />;
  };

  function calculatePosition(current: MouseEvent<HTMLButtonElement>) {
    const targetElement = current.target as HTMLButtonElement;
    const tile = targetElement.closest('.team__button');

    if (window.innerWidth >= tabletsWidth && listRef.current && tile) {
      const currentTileRight = tile.getBoundingClientRect().right;
      const listAreaWidth = listRef.current.getBoundingClientRect().width;
      const listLeftPadding = listRef.current.getBoundingClientRect().left;

      if (currentTileRight - listLeftPadding + listAreaWidth / 2 <= listAreaWidth) {
        return 'right';
      }

      return 'left';
    }

    return null;
  }

  function handleShowDescription(index: number) {
    return (e: MouseEvent<HTMLButtonElement>) => {
      setDescriptionPosition(calculatePosition(e));
      if (index === openDescription) {
        setOpenDescription(null);
      } else {
        setOpenDescription(index);
      }
    };
  }

  function handleCloseInformation() {
    setOpenDescription(null);
  }

  return <section className="team">
    <h2 className="team__header">
      <FormattedMessage
        id={'gritx.home.team.title'}
        defaultMessage={'Meet your team'}
      />
    </h2>
    <ul ref={listRef} className="team__list">
      {
        teammates.map((person, i) => {
          return <li key={i} className="team__item">
            {person.name.defaultMessage
              ? <button onClick={handleShowDescription(i)} className="team__button">
                {getTeammateImage(person)}
                <p className="team__title">
                  {person.name.defaultMessage.length > 0 && <>
                    <span className="visually-hidden">
                      <FormattedMessage
                        id={'gritx.home.person.about'}
                        defaultMessage={'About'}
                      />
                    </span>
                    <FormattedMessage {...person.name} />
                  </>
                  }
                </p>
              </button>
              : <div className="team__button">
                {getTeammateImage(person)}
              </div>
            }
            <div className="team__separator">
            </div>
            {
              openDescription !== null
              && i === openDescription
              && teammates[openDescription].name.defaultMessage.length > 0
              && <div className={cn('team__information', {
                'team__information--left': descriptionPosition === 'left',
                'team__information--right': descriptionPosition === 'right'
              })}>
                <div className="team__information-header">
                  <p className="team__name">
                    <FormattedMessage {...teammates[openDescription].name} />
                  </p>
                  <button
                    className="team__button-close"
                    onClick={handleCloseInformation}
                    aria-label={intl.formatMessage({
                      id: 'gritx.common.button.close',
                      defaultMessage: 'Close'
                    })}
                  >
                    X
                  </button>
                </div>
                <div className="team__information-body">
                  <p className="team__position">
                    <FormattedMessage {...teammates[openDescription].position} />
                  </p>
                  <p className="team__description">
                    <FormattedMessage {...teammates[openDescription].description} />
                  </p>
                </div>
              </div>
            }
          </li>;
        })
      }
    </ul>
  </section>;
};
