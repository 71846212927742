import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useGetTranslations} from '../../utils/hooks/useGetTranslations';
import {TranslationKeys} from '../../utils/constants/routeConstants';
import {
  articleAsyncActions, getTeiArticle, getTeiArticleSections
} from '../../redux/pageAsyncActions';
import './styles.scss';
import Loader from '../../components/loader';
import Section from '../../components/section';

export const ArticleSections: React.FunctionComponent = () => {
  const {
    article: {
      article,
      sections
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {articleId} = useParams();

  useGetTranslations({
    translationKey: TranslationKeys.Article
  });

  useEffect(() => {
    if (articleId) {
      if (!article || article.id.toString() !== articleId) {
        dispatch(articleAsyncActions[getTeiArticle](articleId));
      }
      dispatch(articleAsyncActions[getTeiArticleSections](articleId));
    }
  }, [articleId]);

  function splitByNewLine(text: string) {
    return text.split('\n')
      .map((t, i) => <p key={i}>{t}</p>);
  }

  return article && <div className="sections-page">
    <div className="sections-page-breadcrumbs">
      {article && <div><Link to={`/article/${article.id}`}>
        <FormattedMessage
          id={'gritx.article.article'}
          defaultMessage={'Article'}
        /></Link></div>}
    </div>
    <div className="sections-page-header">
      <div className="sections-page-header__title">
        <FormattedMessage
          id={'gritx.article.sections'}
          defaultMessage={'Sections'}
        />
      </div>
    </div>
    <div>
      {!sections.length && loading.includes(getTeiArticleSections) && <Loader className="sections-page-loader"/>}
      <div className="sections-page-body">
        {sections.map((s, i) => {
          return <Section key={i} section={s}/>;
        })}
      </div>
    </div>
  </div>;
};
