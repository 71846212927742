import {useEffect, useMemo, useState} from 'react';
import {Client, CompatClient, Stomp} from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {StompSubscription} from '@stomp/stompjs/esm6/stomp-subscription';
import {StompHeaders} from '@stomp/stompjs/src/stomp-headers';
import {IFrame} from '@stomp/stompjs/src/i-frame';
import {useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import axios from 'axios';
import {useTimeZoneOffset} from './useTimeZoneOffset';
import {messageCallbackType} from '@stomp/stompjs/src/types';

interface IUseVideoWebsocket {
  changeVideoCallSettings: (videoCallId: number, speechRecognition: boolean, language: string) => void
  sendMessage: (type: string, videoCallId: number | undefined, text: string, showInHistory: boolean) => void
  connected: boolean
  loading: boolean
  connect: (onMessage: (msg: IVideoCallSettings, topic: string) => void) => void
  disconnect: () => void
}

export interface IVideoCallSettings {
  videoCallId: number
  speechRecognition: boolean
  language: string
}

export const useVideoWebSocket = (): IUseVideoWebsocket => {
  const {
    auth: {userProfile},
    dialogue: {
      dialogue, participant
    }
  } = useSelector((state: IStore) => state);
  const [client, setClient] = useState<Client>();
  const [connected, setConnected] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const timeZoneOffset = useMemo(() => useTimeZoneOffset(), []);

  const debug = true;

  function processMessage(msgBody: string) {
    try {
      return JSON.parse(msgBody);
    } catch (e) {
      return msgBody;
    }
  }

  function log(msg: string) {
    if (debug) {
      console.log(msg);
    }
  }

  function cleanUp() {
    setConnected(false);
  }

  const connect = (onMessage: (msg: IVideoCallSettings, topic: string) => void) => {
    setLoading(true);

    const stompClient = new Client({
      webSocketFactory: () => {
        return new SockJS(process.env.REACT_APP_WEBSOCKET_URL || '');
      },
      connectHeaders: {...axios.defaults.headers.common as StompHeaders},
      debug: (str: string) => {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    });

    stompClient.onConnect = (frame: IFrame) => {
      stompClient.subscribe('/user/queue/errors', (message => {
        onMessage(processMessage(message.body), message.headers.destination);
      }), {...axios.defaults.headers.common as StompHeaders});
      setConnected(true);
      setLoading(false);
    };

    stompClient.onWebSocketClose = (frame: IFrame) => {
      setLoading(false);
    };

    stompClient.onStompError = (frame: IFrame) => {
      console.log('onStompError: ', frame);
    };

    stompClient.onWebSocketError = (frame: IFrame) => {
      console.log('onWebSocketError: ', frame);
      setConnected(false);
    };

    stompClient.activate();
    setClient(stompClient);
  };

  function disconnect() {
    if (connected && client) {
      client.deactivate()
        .then(() => {
          cleanUp();
          log('Stomp client is successfully disconnected!');
        });
    }
  }

  function sendMessage(topic: string, msg: string) {
    if (client && connected) {
      client.publish({
        destination: topic,
        body: msg,
        skipContentLengthHeader: true,
        headers: {...axios.defaults.headers.common as StompHeaders}
      });
    } else {
      throw new Error('Send error: SockJsClient is disconnected');
    }
  }

  function changeVideoCallSettings(videoCallId: number, speechRecognition: boolean, language: string) {
    if (!client) {
      return;
    }
    const message = {
      videoCallId: videoCallId,
      speechRecognition: speechRecognition,
      language: language
    };

    sendMessage('/app/video', JSON.stringify(message));
  }

  function sendVideoCallMessage(type: string, videoCallId: number | undefined, text: string,
    showInHistory: boolean) {
    if (!client || !userProfile || !dialogue || !participant) {
      return;
    }
    const message = {
      dialogueID: dialogue.id,
      message: {
        sender: {
          id: participant.userId,
          type: 'CLIENT',
          name: userProfile.email,
          avatar: participant.participantAvatar,
          participantName: participant.participantName,
          zoneOffset: timeZoneOffset
        },
        dialogueId: dialogue.id,
        type: type,
        text: text,
        videoCallId: videoCallId,
        showInHistory: showInHistory
      }
    };

    sendMessage('/app/message', JSON.stringify(message));
  }

  return {
    changeVideoCallSettings: changeVideoCallSettings,
    sendMessage: sendVideoCallMessage,
    connected: connected,
    loading: loading,
    connect: connect,
    disconnect: disconnect
  };
};
