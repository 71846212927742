import {
  CLOSE_ARTICLE, CLOSE_AUTHORS, CLOSE_REFERENCE, CLOSE_SECTION,
  TEI_ARTICLE_AUTHORS_LOADED,
  TEI_ARTICLE_LOADED,
  TEI_ARTICLE_REFERENCE_LOADED,
  TEI_ARTICLE_REFERENCES_LOADED,
  TEI_ARTICLE_SECTION_LOADED,
  TEI_ARTICLE_SECTIONS_LOADED
} from './actions';
import {
  IArticleActionHandler,
  IArticleAuthor,
  IArticleInitialState,
  IArticleReference,
  IArticleReferenceDescr,
  IArticleSection,
  ITeiArticle
} from './interfaces';
import {ArticleActionsType} from './actions';

const ArticleInitialState: IArticleInitialState = {
  article: null,
  authors: [],
  references: [],
  reference: null,
  section: null,
  sections: []
};

const setArticle = ({
  state,
  payload
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    article: payload as ITeiArticle
  };
};

const setAuthors = ({
  state,
  payload
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    authors: payload as IArticleAuthor[]
  };
};

const setReferences = ({
  state,
  payload
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    references: payload as IArticleReferenceDescr[]
  };
};

const setReference = ({
  state,
  payload
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    reference: payload as IArticleReference
  };
};

const setSection = ({
  state,
  payload
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    section: payload as IArticleSection
  };
};

const setSections = ({
  state,
  payload
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    sections: payload as IArticleSection[]
  };
};

const closeArticle = (): IArticleInitialState => {
  return ArticleInitialState;
};

const closeReference = ({
  state
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    reference: null,
    authors: []
  };
};

const closeSection = ({
  state
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    section: null
  };
};

const closeAuthors = ({
  state
}: IArticleActionHandler): IArticleInitialState => {
  return {
    ...state,
    authors: []
  };
};

const statusReducerHandlers = new Map([
  [TEI_ARTICLE_LOADED, setArticle],
  [TEI_ARTICLE_AUTHORS_LOADED, setAuthors],
  [TEI_ARTICLE_REFERENCES_LOADED, setReferences],
  [TEI_ARTICLE_REFERENCE_LOADED, setReference],
  [TEI_ARTICLE_SECTION_LOADED, setSection],
  [TEI_ARTICLE_SECTIONS_LOADED, setSections],
  [CLOSE_ARTICLE, closeArticle],
  [CLOSE_REFERENCE, closeReference],
  [CLOSE_SECTION, closeSection],
  [CLOSE_AUTHORS, closeAuthors]
]);

const articleReducer = (state = ArticleInitialState, action: ArticleActionsType): IArticleInitialState => {
  const handler = statusReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IArticleActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default articleReducer;
