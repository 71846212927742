import React from 'react';
import RemoveIcon from 'assets/icons/square-close.svg';
import './styles.scss';
import {useIntl} from 'react-intl';

interface IFilePreview {
  file: File
  onDelete?: () => void
}

export const FilePreview: React.FunctionComponent<IFilePreview> = ({
  onDelete,
  file
}: IFilePreview) => {
  const intl = useIntl();

  return <div className="file-preview">
    {
      onDelete && <button
        className="file-preview__delete"
        onClick={onDelete}
      >
        <img
          className={'file-preview__delete-img'}
          src={RemoveIcon}
          alt={intl.formatMessage({
            id: 'gritx.common.button.remove',
            defaultMessage: 'Remove'
          })}/>
      </button>
    }
    <div className="file-preview__image-wrapper">
      {
        file.type.includes('image')
        && <img
          className="file-preview__image"
          src={URL.createObjectURL(file)}
          alt=""
        />
      }
    </div>
    <div className="file-preview__info">
      <p className="file-preview__name">
        {file.name}
      </p>
      <p className="file-preview__size">
        {(file.size / 1000000).toFixed(2)} MB
      </p>
    </div>
  </div>;
};
