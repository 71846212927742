import axios, {AxiosResponse} from 'axios';
import {IAvatar} from '../auth/interfaces';
import {IImage} from '@wholesalechange/chatcomponent';

export const getAvatarsApi = (mode: 'USER' | 'BOT'): Promise<AxiosResponse<IAvatar[]>> => {
  return axios.get<IAvatar[]>(`${process.env.REACT_APP_API_URL}/media/avatars/${mode}`);
};
export const getMediaUrlApi = (fileId: number, isMain = false): Promise<AxiosResponse<{ value: string }>> => {
  return axios.get<{ value: string }>(`${process.env.REACT_APP_API_URL}/media/download/${fileId}/${isMain}`);
};
export const deleteMediaApi = (fileId: number): Promise<AxiosResponse<{ value: boolean }>> => {
  return axios.get<{ value: boolean }>(`${process.env.REACT_APP_API_URL}/media/delete/${fileId}`);
};
export const uploadMediaUrlApi = (contentData: string | ArrayBuffer | null, fileName: string): Promise<AxiosResponse<IImage>> => {
  return axios.post<IImage>(`${process.env.REACT_APP_API_URL}/media/upload`, {
    fileName: fileName,
    fileBody: contentData
  });
};
