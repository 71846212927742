import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {IDialogueCard} from 'redux/dialogue/interfaces';
import {IStore} from 'redux/interface';

import './styles.scss';
import {DialogueCard} from '../dialogue-card/DialogueCard';
import Loader from '../loader';

type Props = {
  cards: IDialogueCard[];
  showLoader?: boolean;
  onLoadMore?: (count: number) => void;
  onClickCard: (id: number) => void;
  checkedCards?: number[];
  disabledButtons?: boolean;
}

export const DialoguesCardSection = ({
  showLoader = false,
  cards,
  onClickCard
}: Props): JSX.Element => {
  const {
    auth: {auth0User},
    content: {page}
  } = useSelector((state: IStore) => state);
  const [countCards, setCountCards] = useState(cards.length);
  const [totalElements, setTotalElements] = useState(0);
  const [cardsList, setCardsList] = useState<IDialogueCard[]>(cards);

  useEffect(() => {
    if (page) {
      setTotalElements(page.totalElements);
    }
  }, [page]);

  useEffect(() => {
    setCardsList(cards);
    setCountCards(cards.length);
  }, [cards]);

  function handleClickCard(id: number) {
    return () => {
      onClickCard(id);
    };
  }

  return <section className="dialogues-card-section">
    {showLoader ? <Loader/> : <ul className="dialogues-card-section__list">
      {
        cardsList.map((card, i) => {
          return i < countCards
            ? <li key={card.id} className="dialogues-card-section__item">
              <DialogueCard
                card={card}
                onClick={handleClickCard(card.id)}/>
            </li> : null;
        })
      }
    </ul> }
  </section>;
};
