import React from 'react';
import {FormattedMessage} from 'react-intl';
import ErrorIcon from 'assets/image/toasts/error-icon.svg';
import {GritxToastOptions} from './GritxToastOptions';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

interface IGritxToast {
  options: GritxToastOptions;
}

export const GritxToast: React.FunctionComponent<IGritxToast> = ({options}: IGritxToast) => {
  function refreshPage() {
    window.location.reload();
  }

  return <div className="gritx-toast">
    <img src={ErrorIcon} className="gritx-toast__icon" alt="ErrorIcon"/>
    <div className="gritx-toast__message">
      <p className="gritx-toast__title">
        <FormattedMessage
          id={options.title}
          defaultMessage={'Something went wrong'}
        />
      </p>
      <p className="gritx-toast__description">
        <FormattedMessage
          id={options.description}
          defaultMessage={'Please, hit the refresh button - it might help to fix this'}
        />
      </p>
      {options.showReloadButton ? <button
        className="gritx-toast__button"
        onClick={refreshPage}>
        <FormattedMessage
          id={'gritx.toasts.reloadPage'}
          defaultMessage={'Refresh Page'}
        />
      </button> : null}
    </div>
  </div>;
};
