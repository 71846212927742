import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {logoutAction} from 'redux/auth/authAsyncActions';
import Loader from 'components/loader';

import './styles.scss';

export const Logout = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutAction());
  }, []);

  return <div className="logout container">
    <div className="logout__loader">
      <Loader/>
    </div>
  </div>;
};
