import React, {ReactElement, useCallback, useState} from 'react';
import {Collapse} from 'react-collapse';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as IconDown} from 'assets/image/library/chevron-down.svg';
import {ReactComponent as IconUp} from 'assets/image/library/chevron-up.svg';

import './styles.scss';

type CategoriesProps = {
  children?: ReactElement | null;
};

export const Categories = ({
  children
}: CategoriesProps): JSX.Element => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  const onClick = useCallback(
    () => setIsCollapseOpen(!isCollapseOpen),
    [isCollapseOpen]
  );

  return <div className="categories">
    <button className="categories__header" aria-expanded={isCollapseOpen} onClick={onClick}>
      <div className="categories__title">
        <FormattedMessage
          id={'gritx.resource.categories.header'}
          defaultMessage={'What categories should we show?'}
        />
      </div>
      {isCollapseOpen ? <IconUp className="categories__arrow" /> : <IconDown className="categories__arrow" />}
    </button>
    <Collapse
      isOpened={isCollapseOpen}>
      <div className="categories__content">{children}</div>
    </Collapse>
  </div>;
};
