import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {loadTranslation} from 'redux/translation/translationAsyncActions';

interface IUseGetTranslations {
  translationKey: string
}

export const useGetTranslations = ({
  translationKey
}: IUseGetTranslations): void => {
  const {
    translation: {
      translationKeys,
      locale
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!translationKeys.includes(translationKey)) {
      dispatch(loadTranslation(translationKey));
    }
  }, [locale]);
};
