import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import './styles.scss';

type ToggleButtonProps = {
  icon: string;
  title: string;
  id: number;
  serialNumber: string | undefined;
  onToggle: (serialNumber: string | undefined, id: number, checked: boolean) => void;
  checked?: boolean;
};

export const ToggleButton = ({
  icon,
  title,
  id,
  serialNumber,
  onToggle,
  checked = false
}: ToggleButtonProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    onToggle(serialNumber, id, !isChecked);
    setIsChecked(!isChecked);
  };

  return <div className={cn('toggle-button', {'toggle-button--checked': isChecked})} role="presentation" onClick={handleToggle} title={title}>
    <div className="toggle-button__icon" style={{
      backgroundImage: `url('${icon}')`
    }}>

    </div>
    <div className="toggle-button__title">{title}</div>
  </div>;
};
