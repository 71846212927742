import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {IFilter} from 'redux/content/interfaces';

import Input from 'components/input';
import ToggleButton from '../toggle-button';
import {FilterModel} from '../LibraryModels';
import {LibraryFilter} from '../LibraryEnums';

import './styles.scss';

type FilterBarProps = {
  typeFilters?: FilterModel | undefined;
  timeFilters?: FilterModel | undefined;
  selectedFilters: IFilter[];
  onToggle: (serialNumber: string | undefined, id: number, checked: boolean, filterType: LibraryFilter) => void;
  onChangeSearchString: (value: string) => void;
};

export const FilterBar = ({
  typeFilters,
  timeFilters,
  onToggle,
  onChangeSearchString,
  selectedFilters
}: FilterBarProps): JSX.Element => {
  const intl = useIntl();
  const [typeFiltersSelected, setTypeFiltersSelected] = useState<number[]>([]);
  const [timeFiltersSelected, setTimeFiltersSelected] = useState<number[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    if (typeFilters) {
      setTypeFiltersSelected(selectedFilters.find(item => item.serialNumber === typeFilters.serialNumber)?.inputValue as number[] || []);
    }
    if (timeFilters) {
      setTimeFiltersSelected(selectedFilters.find(item => item.serialNumber === timeFilters.serialNumber)?.inputValue as number[] || []);
    }
    setSearchString(selectedFilters.find(item => item.name === LibraryFilter.SearchString)?.inputValue as string);
  }, [selectedFilters, typeFilters, timeFilters]);

  function handleKeyUp(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      onChangeSearchString(searchString);
    }
  }

  function handleToggle(type: LibraryFilter) {
    return (serialNumber: string | undefined, id: number, checked: boolean) => {
      onToggle(serialNumber, id, checked, type);
    };
  }

  function handleChangeSearchString(value: string) {
    setSearchString(value);
  }

  return <div className="filter-bar">
    <div className="filter-bar__buttons-group">
      {typeFilters && typeFilters.values ? typeFilters.values.map(
        t => <ToggleButton
          checked={typeFiltersSelected.includes(t.id)}
          title={t.name} icon={t.imageUrl}
          key={t.id}
          serialNumber={typeFilters.serialNumber}
          id={t.id}
          onToggle={handleToggle(LibraryFilter.Types)}
        />
      ) : null}
    </div>
    <div className="filter-bar__buttons-group">
      {timeFilters && timeFilters.values ? timeFilters.values.map(
        t => <ToggleButton
          checked={timeFiltersSelected.includes(t.id)}
          title={t.name}
          icon={t.imageUrl}
          key={t.id}
          serialNumber={timeFilters.serialNumber}
          id={t.id}
          onToggle={handleToggle(LibraryFilter.ReadingTime)}
        />
      ) : null}
    </div>
    <div className="filter-bar__search">
      {
        <Input
          className={'filter-bar__search-field'}
          value={searchString}
          placeholder={intl.formatMessage({
            id: 'gritx.library.search.placeholder',
            defaultMessage: 'Search all content by keywords or just any words'
          })}
          onChange={handleChangeSearchString}
          onKeyUp={handleKeyUp}
        />
      }
    </div>
  </div>;
};
