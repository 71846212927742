import React, {FunctionComponent} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';

import {IXpedition} from 'utils/hooks/use-chat-bot/interfaces';
import {useLoadFile} from 'utils/hooks/useLoadFile';
import {IStore} from 'redux/interface';
import BotIcon from 'assets/image/user-settings/BotDefaultAvatar.png';

import Loader from 'components/loader';

import {XpeditionItem} from './xpedition-item/XpeditionItem';

interface IXpeditionList {
  onStart: (botId: number) => void
  onRemove: (dialogId: number) => void
}

export const XpeditionList: FunctionComponent<IXpeditionList> = ({
  onStart,
  onRemove
}: IXpeditionList) => {
  const {
    auth: {userProfile},
    dialogue: {xpeditions}
  } = useSelector((state: IStore) => state);
  const {getFileUrl} = useLoadFile();

  function handleStartXpedition(botId: number) {
    onStart(botId);
  }

  function handleRemoveDialog(dialogId: number) {
    onRemove(dialogId);
  }

  function getBotAvatar() {
    return userProfile?.dialogAvatarId ? getFileUrl(userProfile.dialogAvatarId) : BotIcon;
  }

  if (xpeditions.length === 0) {
    return <Loader nested/>;
  }

  const renderContent = () => {
    return <>
      {
        xpeditions.map(item => {
          return <XpeditionItem
            key={item.id}
            xpedition={item}
            onStart={handleStartXpedition}
            onRemove={handleRemoveDialog}
          />;
        })
      }
    </>;
  };
  const renderMessages = () => {
    return <div className="bot-chat__wrapper">
      <div className="bot-chat__start-message">
        <p>
          <FormattedMessage
            id={'gritx.xpedition.message'}
            defaultMessage={'Welcome to the place where all journeys start.'}
          />
        </p>
        <p>
          <FormattedMessage
            id={'gritx.xpedition.description'}
            defaultMessage={'Choose the adventure and let\'s dive in!'}
          />
        </p>
      </div>
    </div>;
  };

  return <div className="bot">
    <div className="bot__wrapper">
      <div className="bot__window">
        <div className="bot-chat">
          <div className="bot-chat__toolbar">
            <div className="chat__bot-avatar-wrapper">
              <img
                src={getBotAvatar()}
                alt=""
                className="chat__bot-avatar"
              />
            </div>
          </div>
          {renderMessages()}
          <div className="bot-chat__toolbar"/>
        </div>
      </div>

      <div className="bot__content">
        {renderContent()}
      </div>
    </div>
  </div>;
};
