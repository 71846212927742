import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import cn from 'classnames';

import {IArticle} from 'redux/content/interfaces';

import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import ModalWindow from 'components/modal-window';

import './styles.scss';

const delay = 50;
const scale = 1;
const imageSize = 300;
const initialRadius = 70;
const initialFontSize = 18;

interface IMeditationArticle {
  card: IArticle
}

enum MeditationMedia {
  background = 'BACKGROUND',
  audio = 'AUDIO'
}

enum MeditationSteps {
  'breathIn' = 'breathIn',
  'breathOut' = 'breathOut',
  'holdIn' = 'holdIn',
  'holdOut' = 'holdOut',
}

function getStep(currentStep: MeditationSteps) {
  switch (currentStep) {
    case MeditationSteps.breathIn:
      return MeditationSteps.holdIn;
    case MeditationSteps.holdIn:
      return MeditationSteps.breathOut;
    case MeditationSteps.breathOut:
      return MeditationSteps.holdOut;
    default:
      return MeditationSteps.breathIn;
  }
}

function startTimer(callback: () => void) {
  return setInterval(() => {
    callback();
  }, delay);
}

function stopTimer(id: NodeJS.Timeout) {
  clearInterval(id);
}

function getTimeInSec(time: number): number {
  return Math.floor(time / 1000);
}

export const Meditation = ({card}: IMeditationArticle): JSX.Element => {
  const audioRef = useRef<HTMLMediaElement>(null);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [meditationDuration, setMeditationDuration] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [currentStep, setCurrentStep] = useState<MeditationSteps>(MeditationSteps.breathIn);
  const [currentStepTime, setCurrentStepTime] = useState(0);
  const [isShowStartButton, setIsShowStartButton] = useState(false);

  function handleTimerTick() {
    let time = -1;
    // calculate time

    if (audioRef.current) {
      time = Math.floor(audioRef.current.currentTime * 1000);
    }
    setCurrentTime(time);
    if (time >= meditationDuration && timerId) {
      stopTimer(timerId);
    }
  }

  useEffect(() => {
    setCurrentStepTime(card.breathIn);
  }, [card]);

  useEffect(() => {
    if (isOpen && meditationDuration) {
      setTimerId(startTimer(handleTimerTick));
    }
  }, [isOpen, meditationDuration]);

  useEffect(() => {
    // calculate steps
    if (currentStepTime <= 0 && currentTime <= meditationDuration) {
      const step = currentTime !== 0 ? getStep(currentStep) : currentStep;

      setCurrentStep(step);
      if (typeof card[step] === 'number') {
        setCurrentStepTime(card[step] as number);
      } else {
        setCurrentStepTime(0);
      }
    } else {
      setCurrentStepTime(currentStepTime - delay);
    }
  }, [currentTime]);

  function handleStartMeditation() {
    setIsOpen(true);
  }

  function handleStopMeditation() {
    setIsOpen(false);
    setCurrentTime(0);
    setCurrentStep(MeditationSteps.breathIn);
    setCurrentStepTime(card.breathIn);
    setIsShowStartButton(false);
    window.scrollTo(0, 0);

    if (timerId) {
      stopTimer(timerId);
    }
  }

  function getMediaUrl(type: MeditationMedia) {
    return card.mediaList?.filter(item => item.type === type)[0]?.url || '';
  }

  function handleLoadMetadata() {
    const audio = audioRef.current;

    if (audio) {
      setMeditationDuration(audio.duration * 1000);
      setIsShowStartButton(false);
      audio.play();
    }
  }

  function getScaleModification() {
    switch (currentStep) {
      case MeditationSteps.breathIn:
        return 1 + (scale * (card.breathIn - currentStepTime)) / card.breathIn;
      case MeditationSteps.holdIn:
        return 1 + scale;
      case MeditationSteps.breathOut:
        return 1 + scale + (scale * (currentStepTime - card.breathOut)) / card.breathOut;
      default:
        return 1;
    }
  }

  const radius = initialRadius * getScaleModification();
  const calculateSector = () => ((2 * Math.PI * radius * currentTime) / meditationDuration) || 0;

  const isShowMeditationMessage = getTimeInSec(meditationDuration - 15000) <= getTimeInSec(currentTime)
    && getTimeInSec(currentTime) !== getTimeInSec(meditationDuration);

  function handleStart() {
    if (audioRef.current) {
      audioRef.current.play();
    }
    setCurrentTime(0);
    setCurrentStep(MeditationSteps.breathIn);
    setCurrentStepTime(card.breathIn);
    setIsShowStartButton(false);
  }

  if (currentTime && getTimeInSec(meditationDuration) === getTimeInSec(currentTime)) {
    setTimeout(() => {
      setIsShowStartButton(true);
    }, 5000);
  }

  return <div className="meditation">
    <GritxButton
      className="meditation__open-button"
      title={intl.formatMessage({
        id: 'gritx.content-page.meditation.begin',
        defaultMessage: 'Begin the exercise'
      })}
      variant={ButtonVariant.Primary}
      onClick={handleStartMeditation}
    />
    <ModalWindow
      show={isOpen}
      className="meditation__modal"
      onHide={handleStopMeditation}
      styles={{
        content: {
          backgroundImage: `url(${getMediaUrl(MeditationMedia.background)})`
        },
        overlay: {
          padding: 0
        }
      }}
    >
      <>
        {
          getMediaUrl(MeditationMedia.audio)
            ? <>
              <audio
                ref={audioRef}
                src={getMediaUrl(MeditationMedia.audio)}
                onLoadedMetadata={handleLoadMetadata}
              >
                <track kind="captions" src="/assets/meditation.vtt"/>
              </audio>
            </>
            : null
        }
        {
          !isShowStartButton
            ? <>
              <div className="meditation__circle">
                <svg width={imageSize} height={imageSize}>
                  <circle
                    transform={'rotate(-90 150 150)'}
                    cx={imageSize / 2}
                    cy={imageSize / 2}
                    r={radius}
                  />
                  <circle
                    transform={'rotate(-90 150 150)'}
                    cx={imageSize / 2}
                    cy={imageSize / 2}
                    r={radius}
                    style={{
                      strokeDasharray: `${calculateSector()}px ${2 * Math.PI * radius}px`
                    }}
                  />
                </svg>
                <p
                  className="meditation__step"
                  style={{
                    fontSize: initialFontSize,
                    animationDuration: `${card[currentStep]}ms`,
                    animationName: !isShowStartButton ? currentStep : 'none'
                  }}
                >
                  <FormattedMessage
                    id={`gritx.content-page.meditation.${currentStep}`}
                    defaultMessage={currentStep}
                  />
                </p>
              </div>

              <div className="meditation__description">
                <p
                  className={cn('meditation__text', {
                    'meditation__text--show': isShowMeditationMessage
                  })}
                >
                  <FormattedMessage
                    id={'gritx.breathing.message.one-more-time'}
                    defaultMessage={'One more time'}
                  />
                </p>
                <div className={cn(
                  'meditation__last-message', {
                    'meditation__last-message--show': currentTime && getTimeInSec(meditationDuration) === getTimeInSec(currentTime)
                  }
                )}
                >
                  <p className="meditation__text meditation__text--show">
                    <FormattedMessage
                      id={'gritx.breathing.message.return-to-normal'}
                      defaultMessage={'Return to Normal Breathing'}
                    />
                  </p>

                </div>
              </div>
            </>
            : <div className="meditation__description">
              <GritxButton
                title={intl.formatMessage({
                  id: 'gritx.common.button.start',
                  defaultMessage: 'Start'
                })}
                variant={ButtonVariant.Outline}
                onClick={handleStart}
              />
            </div>
        }
      </>
    </ModalWindow>
  </div>;
};
