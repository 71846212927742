import React from 'react';

import './styles.scss';

type Props = {
  email: string;
  deleteEmail: () => void;
}

export const EmailBox = ({
  email,
  deleteEmail
}: Props): JSX.Element => {
  return <div className="email-box">
    {email}
    <button className="email-box__delete-btn" onClick={deleteEmail}>x</button>
  </div>;
};
