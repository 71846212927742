import React, {useEffect, useState} from 'react';
import {
  dialogueAsyncActions,
  getDialogue, getDialogueParticipant,
  getTranslations
} from '../../redux/pageAsyncActions';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {Link, useParams} from 'react-router-dom';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';

import './styles.scss';
import {DialogueChat} from '../../components/dialogue-chat/DialogueChat';
import {clearParticipantErrorAction, dialogueClearedAction} from '../../redux/dialogue/actions';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import NotFoundInfo from '../../components/not-found-info';

export const Dialogue: React.FunctionComponent = () => {
  const {
    auth: {userProfile},
    dialogue: {
      dialogue, participant, participantError
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const {dialogueId} = useParams();
  const [isShowParticipants, setShowParticipants] = useState<boolean>(false);
  const [isParticipantNotFound, setParticipantNotFound] = useState<boolean>(false);

  function onShowParticipantsPanel() {
    setShowParticipants(!isShowParticipants);
  }

  function handleHideParticipants() {
    setShowParticipants(false);
  }

  useEffect(() => {
    if (dialogueId) {
      dispatch(dialogueAsyncActions[getDialogue](Number(dialogueId)));
    }

    return () => {
      dispatch(dialogueClearedAction());
    };
  }, [dialogueId]);

  useEffect(() => {
    if (dialogue && userProfile) {
      dispatch(dialogueAsyncActions[getDialogueParticipant](userProfile.id, dialogue.id));
    }
  }, [userProfile, dialogue]);

  useEffect(() => {
    dispatch(clearParticipantErrorAction());
  }, []);

  useEffect(() => {
    if (participantError) {
      setParticipantNotFound(true);
    }
  }, [participantError]);

  function renderBreadcrumbs() {
    return <div className="breadcrumbs">
      <Link className="breadcrumbs__previous" to="/Dialogues">
        <FormattedMessage
          id={'gritx.content-page.Dialogues.breadcrumb.previous'}
          defaultMessage="Dialogues"
        />
      </Link> - <span className="breadcrumbs__current">
        {dialogue?.name}
      </span>
    </div>;
  }

  function renderDialoguePanel() {
    return !loading.includes(getTranslations) && participant && <>
      {renderBreadcrumbs()}
      <div className="dialogue__header">
        <GritxButton
          title={intl.formatMessage({
            id: 'gritx.dialogue.participants',
            defaultMessage: 'Participants'
          })}
          className={'dialogue__button'}
          variant={ButtonVariant.Primary}
          onClick={onShowParticipantsPanel}
        />
      </div>
      <DialogueChat dialogue={dialogue}
        participant={participant}
        isShowParticipants={isShowParticipants}
        onHideParticipants={handleHideParticipants}/>
    </>;
  }

  return <div className="dialogue">
    {renderDialoguePanel()}
    {isParticipantNotFound
      && <NotFoundInfo
        title={intl.formatMessage({
          id: 'gritx.dialogue.notFound',
          defaultMessage: 'Sorry, but nothing can be found.'
        })}
        image={CrossroadImg}
      />
    }
  </div>;
};
