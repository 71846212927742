import React, {ReactElement} from 'react';
import Header from 'components/header';
import Footer from 'components/footer';

import './styles.scss';

type Props = {
  hideSeparator?: boolean;
  children: ReactElement;
}

export const Layout = ({hideSeparator, children}: Props): JSX.Element => {
  return <div className={'layout'}>
    <Header/>
    <main className={'main'}>
      <div className="container">
        {children}
      </div>
    </main>
    <Footer hideSeparator={hideSeparator}/>
  </div>;
};
