import React from 'react';
import './styles.scss';

interface Props {
  title?: string;
  description?: string;
  image: string;
}

export const NotFoundInfo = ({title, description, image}: Props): JSX.Element => {
  return <div className="not-found">
    <h2 className="not-found__text">
      {title}
    </h2>
    <p className="not-found__text">{description}</p>
    <img
      className="not-found__image"
      src={image}
      alt=""
    />
  </div>;
};
