import {IVideoCall} from '../../redux/dialogue/interfaces';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../redux/interface';
import {useState} from 'react';
import {errorHandler} from '../../redux/errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {
  createVideoCall,
  finishVideoCall,
  getVideoCallActive, getVideoCallRtmToken,
  getVideoCallToken,
  setDialogueLanguage
} from '../../redux/pageAsyncActions';

interface IUseVideoDialogue {
  isLoading: boolean
  createVideoCall: (dialogueId: number) => Promise<IVideoCall | null>
  getVideoCallToken: (channelName: string) => Promise<string | null>
  getVideoCallRtmToken: (userId: string) => Promise<string | null>
  finishVideoCall: (videoCallId: number) => Promise<boolean>
  getVideoCallActive: (videoCallId: number) => Promise<boolean>
  setDialogueLanguage: (dialogueId: number, language: string) => Promise<boolean>
}

export const useVideoDialogue = (): IUseVideoDialogue => {
  const {
    auth: {
      auth0Client,
      auth0User
    }
  } = useSelector((store: IStore) => store);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function getToken(): Promise<string> {
    try {
      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        return await auth0Client.getTokenSilently();
      }
    } catch (e) {
      console.log('useVideoDialogue getToken', e);
      dispatch(errorHandler({
        toastKey: ToastKey.GetToken,
        actionKey: 'useVideoDialogue getToken',
        error: e
      }));
    }

    return '';
  }

  function getHeaders(token: string): { Authorization: string, API_KEY: string | undefined } {
    return {
      Authorization: token,
      API_KEY: process.env.REACT_APP_API_KEY
    };
  }

  async function createVideoCallAction(dialogueId: number): Promise<IVideoCall | null> {
    setIsLoading(true);
    const token = await getToken();
    const actionKey = createVideoCall;

    try {
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}/video/start`,
        {
          dialogId: dialogueId,
          userId: auth0User?.sub
        },
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);
      console.log('createVideoCallAction', error);
      dispatch(errorHandler({
        toastKey: ToastKey.CreateVideoCallAction,
        actionKey,
        error: error
      }));

      return null;
    }
  }

  async function getVideoCallTokenAction(channelName: string): Promise<string | null> {
    setIsLoading(true);
    const token = await getToken();
    const actionKey = getVideoCallToken;

    try {
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}/agora/generate/token`,
        {
          channel: channelName,
          userId: 0
        },
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);
      console.log('getVideoCallTokenAction', error);
      dispatch(errorHandler({
        toastKey: ToastKey.GetVideoCallTokenAction,
        actionKey,
        error: error
      }));

      return null;
    }
  }

  async function getVideoCallRtmTokenAction(userId: string): Promise<string | null> {
    setIsLoading(true);
    const token = await getToken();
    const actionKey = getVideoCallRtmToken;

    try {
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}/agora/generate/rtm/token`,
        {
          userId: userId
        },
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);
      console.log('getVideoCallRtmTokenAction', error);
      dispatch(errorHandler({
        toastKey: ToastKey.GetVideoCallTokenAction,
        actionKey,
        error: error
      }));

      return null;
    }
  }

  async function finishVideoCallAction(videoCallId: number): Promise<boolean> {
    setIsLoading(true);
    const token = await getToken();
    const actionKey = finishVideoCall;

    try {
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}/video/end`,
        {
          id: videoCallId
        },
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return data.value;
    } catch (error) {
      setIsLoading(false);
      console.log('finishVideoCallAction', error);
      dispatch(errorHandler({
        toastKey: ToastKey.FinishVideoCallAction,
        actionKey,
        error: error
      }));

      return false;
    }
  }

  async function getVideoCallActiveAction(dialogueId: number): Promise<boolean> {
    setIsLoading(true);
    const token = await getToken();
    const actionKey = getVideoCallActive;

    try {
      const {data} = await axios.get(
        `${process.env.REACT_APP_API_URL}/video/active/${dialogueId}`,
        {headers: getHeaders(token)}
      );

      setIsLoading(false);

      return data.value;
    } catch (error) {
      setIsLoading(false);
      console.log('getVideoCallActiveAction', error);
      dispatch(errorHandler({
        toastKey: ToastKey.GetVideoCallActiveAction,
        actionKey,
        error: error
      }));

      return false;
    }
  }

  async function setDialogueLanguageAction(dialogueId: number, language: string) : Promise<boolean> {
    const token = await getToken();
    const actionKey = setDialogueLanguage;

    try {
      const {data} = await axios.post(
        `${process.env.REACT_APP_API_URL}/dialog/set/language`,
        {
          id: dialogueId,
          language: language
        },
        {headers: getHeaders(token)}
      );

      return data.value;
    } catch (error) {
      console.log('getVideoCallActiveAction', error);
      dispatch(errorHandler({
        toastKey: ToastKey.SetDialogLanguage,
        actionKey,
        error: error
      }));

      return false;
    }
  }

  return {
    isLoading,
    createVideoCall: createVideoCallAction,
    getVideoCallToken: getVideoCallTokenAction,
    getVideoCallRtmToken: getVideoCallRtmTokenAction,
    finishVideoCall: finishVideoCallAction,
    getVideoCallActive: getVideoCallActiveAction,
    setDialogueLanguage: setDialogueLanguageAction
  };
};
