import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useDispatch, useSelector} from 'react-redux';
import {IFilter, ILibraryFiltersResponse} from 'redux/content/interfaces';
import {IStore} from 'redux/interface';
import {setSelectedFilters} from 'redux/content/actions';

import FilterBar from './filter-bar';
import Situation from './situation';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import {FilterModel, FilterValuesModel} from './LibraryModels';
import {LibraryFilter} from './LibraryEnums';
import {Situations} from './situations/Situations';

import './styles.scss';

interface ILibraryFilters {
  filters: ILibraryFiltersResponse | null
  onChange: (filters: IFilter[]) => void
}

export const LibraryFilters: FunctionComponent<ILibraryFilters> = ({
  filters,
  onChange
}: ILibraryFilters) => {
  const {
    content: {
      selectedFilters
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [situationFilterList, setSituationFilterList] = useState<FilterModel>();
  const [typeFilterList, setTypeFilterList] = useState<FilterModel>({} as FilterModel);
  const [timeFilterList, setTimeFilterList] = useState<FilterModel>({} as FilterModel);

  function getFilters(typeFilter: LibraryFilter): FilterModel {
    const filterList = filters?.filters?.find(a => a.name === typeFilter);

    return {
      serialNumber: filterList?.serialNumber,
      values: filterList?.allowValues as FilterValuesModel[]
    };
  }

  function dispatchFilters(filterList: IFilter[]) {
    dispatch(setSelectedFilters(filterList));
  }

  function changeSituationFilter(id: number, checked: boolean) {
    const updatedSituations = Object.assign({}, situationFilterList);

    updatedSituations.values = updatedSituations?.values.map(sv => {
      if (sv.id === id) {
        return {...sv, checked};
      }

      return sv;
    });
    setSituationFilterList(updatedSituations);
  }

  useEffect(() => {
    const filterList = getFilters(LibraryFilter.LifeSituations);

    setTypeFilterList(getFilters(LibraryFilter.Types));
    setTimeFilterList(getFilters(LibraryFilter.ReadingTime));

    if (!selectedFilters.length) {
      setSituationFilterList(filterList);

      const filtersList = filters?.filters.map((filter) => {
        return {
          serialNumber: filter.serialNumber,
          name: filter.name,
          format: filter.format,
          inputValue: filter.name === LibraryFilter.Types || filter.name === LibraryFilter.ReadingTime ? filter.allowValues?.map(item => item.id) || [] : []
        };
      });

      if (filtersList) {
        onChange(filtersList);
        dispatchFilters(filtersList);
      }
    } else {
      const challengeValues = selectedFilters.find(a => a.name === LibraryFilter.LifeSituations)?.inputValue as number [];
      const mappedFilterValues = filterList.values?.map((val: FilterValuesModel) => {
        if (challengeValues && challengeValues.find(cv => cv === val.id)) {
          return {...val, checked: true};
        }

        return val;
      });

      filterList.values = mappedFilterValues;
      setSituationFilterList(filterList);
    }
  }, [filters]);

  const handleToggle = (serialNumber: string | undefined, id: number, checked: boolean, filterType: LibraryFilter) => {
    if (filterType === LibraryFilter.LifeSituations) {
      changeSituationFilter(id, checked);
    }

    const updatedFilters = selectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.serialNumber === serialNumber);

    if (checked) {
      updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue, id] as number[];
    } else {
      updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue].filter(item => item !== id) as number[];
    }
    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
  };

  const handleChangeSearchString = (value: string) => {
    const updatedFilters = selectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.SearchString);

    updatedFilters[filterIdx].inputValue = value;
    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
  };

  const handleClear = () => {
    const updatedFilters = selectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.LifeSituations);

    updatedFilters[filterIdx].inputValue = [];

    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
    const updatedSituations = Object.assign({}, situationFilterList);

    updatedSituations.values = updatedSituations?.values.map(sv => {
      return {...sv, checked: false};
    });
    setSituationFilterList(updatedSituations);
  };

  return <div className="library-filters">
    <Situations>
      <>
        <div className="library-filters__situations">
          {situationFilterList?.values?.map(v => {
            return (
              <Situation
                title={v.name}
                key={v.id}
                serialNumber={situationFilterList.serialNumber}
                id={v.id}
                checked={v.checked}
                onToggle={handleToggle}/>
            );
          })}
        </div>
        <div className="library-filters__button">
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.library.clear-situation',
              defaultMessage: ' Clear Situations'
            })}
            variant={ButtonVariant.Outline}
            onClick={handleClear}
          />
        </div>
      </>
    </Situations>
    {typeFilterList && timeFilterList ? <FilterBar
      selectedFilters={selectedFilters}
      typeFilters={typeFilterList}
      timeFilters={timeFilterList}
      onToggle={handleToggle}
      onChangeSearchString={handleChangeSearchString}
    /> : null}
  </div>;
};
