import axios from 'axios';
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {IStore} from 'redux/interface';
import {finishLoadingAction, startLoadingAction} from 'redux/status/actions';
import {getTranslations} from 'redux/pageAsyncActions';
import {errorHandler} from 'redux/errorHandler';
import {changeLocaleAction, updateTranslationsAction} from './actions';
import {ToastKey} from 'components/gritx-toast/ToastKeyEnum';
import {ITranslation} from './interfaces';
import {SystemLanguage} from 'utils/constants/constants';

function fixedTranslations(translations: ITranslation, locale: string): ITranslation {
// todo remove after updating translations
  if (process.env.REACT_APP_HIDE_TRANSLATION_PREFIX === 'true' && locale !== SystemLanguage.English) {
    return Object.keys(translations)
      .reduce((tr, key: string) => {
        if (translations[key].trim().slice(0, 3)
          .toLowerCase()
          .includes(`${locale} `)) {
          return {
            ...tr,
            [key]: translations[key].slice(3)
          };
        }

        return {
          ...tr,
          [key]: translations[key]
        };
      }, {});
  }

  return translations;
}

export const loadTranslation = (translationKey: string): ThunkAction<void, IStore, unknown, Action> => {
  return (dispatch, getState) => {
    const {translation: {locale} } = getState();

    dispatch(startLoadingAction(getTranslations));

    axios.get(`/lang/${locale}/${translationKey}.json`)
      .then(({data}) => {
        dispatch(updateTranslationsAction({
          locale,
          translationKey,
          translations: fixedTranslations(data, locale)
        }));
        dispatch(finishLoadingAction(getTranslations));
      })
      .catch((e) => {
        console.log('loadTranslation', e);
        dispatch(errorHandler({
          toastKey: ToastKey.LoadTranslation,
          actionKey: 'loadTranslation',
          error: e,
          currentAction: loadTranslation(translationKey)
        }));
      });
  };
};
export const changeLocale = (locale: string): ThunkAction<void, IStore, unknown, Action> => (dispatch, getState) => {
  const {
    translations,
    translationKeys
  } = getState().translation;

  const keysForFetch = translationKeys.reduce((acc: string[], key) => {
    if (Object.keys(translations[locale])
      .find((translationKey: string): boolean => translationKey.includes(key))) {
      return acc;
    }

    return [...acc, key];
  }, []);

  if (keysForFetch.length) {
    dispatch(startLoadingAction(getTranslations));
    Promise.all(keysForFetch.map((key) => axios.get(`/lang/${locale}/${key}.json`)))
      .then((results) => {
        dispatch(updateTranslationsAction({
          locale,
          translations: results.map(response => response.data)
            .reduce((acc, data) => {
              return {...acc, ...fixedTranslations(data, locale)};
            }, {})
        }));
        dispatch(changeLocaleAction(locale));
        dispatch(finishLoadingAction(getTranslations));
      })
      .catch((e) => {
        dispatch(errorHandler({
          toastKey: ToastKey.ChangeLocale,
          actionKey: 'changeLocale',
          error: e,
          currentAction: changeLocale(locale)
        }));
      });
  } else {
    dispatch(changeLocaleAction(locale));
  }
};
