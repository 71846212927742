import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {
  getResourceContent,
  getResourceFilters,
  getTranslations,
  resourceAsyncActions
} from 'redux/pageAsyncActions';
import {IFilter} from 'redux/content/interfaces';
import {maxArticleCards} from 'utils/constants/constants';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import CrossroadImg from 'assets/image/library/skill-crossroad.svg';
import {ReactComponent as BookIcon} from 'assets/image/library/book.svg';

import CardSection from 'components/card-section';
import Loader from 'components/loader';
import NotFoundInfo from 'components/not-found-info';

import './styles.scss';
import {ResourceFilters} from './ResourceFilters';

export const Resource: React.FunctionComponent = () => {
  const {
    content: {
      resourceContent,
      resourceFilters
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useNavigate();
  const intl = useIntl();
  const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([]);

  useGetTranslations({
    translationKey: TranslationKeys.Resource
  });

  useEffect(() => {
    dispatch(resourceAsyncActions[getResourceFilters]());
  }, []);

  function loadContent({
    filters,
    offset
  }: { filters: IFilter[]; offset: number }) {
    dispatch(resourceAsyncActions[getResourceContent](
      {
        filters,
        offset,
        pageSize: maxArticleCards
      }
    ));
  }

  function handleLoadMore(offset: number) {
    loadContent({
      filters: selectedFilters,
      offset
    });
  }

  function handleChangeFilters(changedFilters: IFilter[]) {
    setSelectedFilters(changedFilters);
    loadContent({
      filters: changedFilters,
      offset: 0
    });
  }

  function handleOpenCard(cardId: number) {
    history(`/content#${cardId}`);
  }

  function renderFilters() {
    if (resourceFilters) {
      return <ResourceFilters filters={resourceFilters} onChange={handleChangeFilters}/>;
    }

    return null;
  }

  return <section className={'resource container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="resource__title"><BookIcon className="resource__icon"/>
          <FormattedMessage
            id={'gritx.resource.title'}
            defaultMessage={'Resources'}
          />
        </h1>
        <div className="resource__filters">
          {
            loading.includes(getResourceFilters) ? <Loader/> : renderFilters()
          }
        </div>
        <div className="resource__cards">
          {resourceContent.length === 0 && resourceFilters ? <NotFoundInfo
            title={intl.formatMessage({
              id: 'gritx.resource.sectionCards.notFound',
              defaultMessage: 'Sorry, but nothing can be found. Try the search or apply fewer filters.'
            })}
            image={CrossroadImg}
          /> : <CardSection
            showLoader={loading.includes(getResourceFilters)}
            cards={resourceContent}
            onLoadMore={handleLoadMore}
            onClickCard={handleOpenCard}
          />}
        </div>
      </>
    }
  </section>;
};
